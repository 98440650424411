import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Tooltip, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import EscapeText from '../../Common/EscapeText';
import QuotationQuestionReply from '../../Interfaces/QuotationQuestionReply';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

const { Text } = Typography;

interface EditQuotationQuestionReplyModalProps { 
  questionReply: Partial<QuotationQuestionReply>[];
  targetQuestionReply: Partial<QuotationQuestionReply>;
  setQuestionReply: Dispatch<SetStateAction<Partial<QuotationQuestionReply>[]>>;
}

const EditQuotationQuestionReplyModal = ({
  questionReply,
  targetQuestionReply,
  setQuestionReply,
}: EditQuotationQuestionReplyModalProps) => {

  let [isQuotationQuestionReplyModalVisible, setIsQuotationQuestionReplyModalVisible] = useState<boolean>(false);
  let [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const showQuotationQuestionReplyModal = (): void => setIsQuotationQuestionReplyModalVisible(true);
  const hideQuotationQuestionReplyModal = (): void => {
    setIsQuotationQuestionReplyModalVisible(false);
    setConfirmLoading(false);
  }
  const onOk = (): void => form.submit();

  const onFinish = (values: any): void => {
    setConfirmLoading(true);
    const editQuestionReply: Partial<QuotationQuestionReply> = {};
    editQuestionReply.id = targetQuestionReply.id;
    editQuestionReply.reply = values.reply;
    axios({method: 'PATCH', url: `/base/quotation_question_reply/${targetQuestionReply.id}/`, data: editQuestionReply})
	.then(() => {
		let newQuestionReply = cloneDeep(questionReply);
		for (var i = 0; i < newQuestionReply.length; i++) {
			if (newQuestionReply[i].id === targetQuestionReply.id) {
				newQuestionReply[i].reply = values.reply;
			}
		}
		setQuestionReply(newQuestionReply);
		hideQuotationQuestionReplyModal();
	})
    .catch(function (error) {
      console.log(error)
    });
  }

  useEffect(() => {
    form.setFieldsValue({
      reply: targetQuestionReply.reply,
    });
  }, [targetQuestionReply, form]);

  return (
    <>
      <Tooltip placement="top" title="Edit">
        <Button onClick={showQuotationQuestionReplyModal} size="small">
          <EditOutlined />
        </Button>
      </Tooltip>
      <Modal 
        confirmLoading={confirmLoading}
        title="Response Extra Question"
        visible={isQuotationQuestionReplyModalVisible}
        onOk={onOk}
        okText="Submit"
        onCancel={hideQuotationQuestionReplyModal}
        width="98%"
        keyboard={false}
        maskClosable={false}
        style={{ top: 20 }}>
        <Form 
          layout="vertical"
          name="quotationQuestionReplyForm"
          form={form}
          onFinish={onFinish}
        >
          	<Form.Item label="Question">
      			  <Text strong><EscapeText text={targetQuestionReply.material_request_extra_question_display}/></Text>
			      </Form.Item>
            <Form.Item label="Response" name="reply">
              <Input />
            </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default EditQuotationQuestionReplyModal;
