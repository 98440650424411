import React, { useEffect, useState } from 'react';
import { Spin, Table} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { NavLink, useSearchParams, useParams} from 'react-router-dom';

import './rfq_details.css';
import AddQuotationModal from '../Common/Quotation/AddQuotationModal';
import RFQDetailsTable from '../Common/RFQ/RFQDetailsTable';
import { QuotationsColumns } from '../Common/Quotation/QuotationTableColumns';
import { timelineClass } from '../Common/TimelineHelper';
import EscapeText from '../Common/EscapeText';
import CurrentUser from '../Interfaces/CurrentUser';
import MaterialRequestFile from '../Interfaces/MaterialRequestFile';
import Quotation from '../Interfaces/Quotation';
import QuotationFile from '../Interfaces/QuotationFile';
import QuotationItem from '../Interfaces/QuotationItem';
import RFQ from '../Interfaces/RFQ';
import RFQItem from '../Interfaces/RFQItem';
import RFQPublication from '../Interfaces/RFQPublication';
import XrefRFQStatus from '../Interfaces/XrefRFQStatus';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import MaterialRequestItemSpec from '../Interfaces/MaterialRequestItemSpec';
import Status from '../Interfaces/Status';
import MaterialRequestExtraQuestion from '../Interfaces/MaterialRequestExtraQuestion';

interface RFQDetailsProps { 
  user: Partial<CurrentUser>;
}

const env = (window as { [key: string]: any })["_env_"];

const RFQDetails = ({user}: RFQDetailsProps) => {

  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [currentVersion, setCurrentVersion] = useState<Partial<RFQ>>({});
  const [items, setItems] = useState<Partial<RFQItem>[]>([]);
  const [quotations, setQuotations] = useState<Partial<Quotation>[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeline, setTimeline] = useState<Partial<XrefRFQStatus>[]>([]);
  const [publications, setPublications] = useState<Partial<RFQPublication>[]>([]);
  const [MRQFileTypes, setMRQFileTypes] = useState<Partial<MaterialRequestFile>[]>([]);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [allSpecs, setAllSpecs] = useState<string[]>([]);
  const [activeStatus, setActiveStatus] = useState<Partial<Status>>({});
  const [extraQuestion, setExtraQuestion] = useState<Partial<MaterialRequestExtraQuestion>[]>([]);

  const version = searchParams.get("version");
  const urlParams = useParams();

  useEffect(() => {
    /** get all versions of rfq for the given rfq_header */
    axios(`/base/rfq/?limit=10000&rfq_header=${urlParams.rfqId}`)
    .then(function (response) {
      setInitialLoad(false);
      /** find the version we want to display and set it as currentVersion in state */
      const displayRequest = response.data.results.find((rfq: Partial<RFQ>) => String(rfq.version) === version);
      setCurrentVersion(displayRequest || {});
      console.log(displayRequest)
      /** gather rfq items and for the displayed rfq */
      axios.all([
        axios(`/base/rfq_item/?limit=10000&rfq=${displayRequest.id}`),
        axios(`/base/quotation/?limit=1000&rfq=${displayRequest.id}`),
        axios(`/base/xref_rfq_status/?limit=100&rfq=${displayRequest.id}&fields=status,created_at,created_by,id&ordering=-created_at`),
        axios(`/base/rfq_publication/?limit=100&ordering=-created_at&status__name=Active&rfq=${displayRequest.id}`),
        axios(`/base/material_request_file/?limit=1000&material_request=${displayRequest.material_request}&ordering=file_type__file_type_name`),
        axios(`/base/quotation_file/?limit=1000&quotation__rfq=${displayRequest.id}`),
        axios(`/base/material_request_item_spec/?limit=10000&material_request_item__material_request=${displayRequest.material_request}`),
        axios(`/main/status/?status_type__name=Is Active&name=Active`),
        axios(`/base/material_request_extra_question/?material_request=${displayRequest.material_request}&ordering=seq`),
      ]).then(axios.spread((...responses) => {
        setActiveStatus(responses[7].data.results[0]);
        setItems(responses[0].data.results.map((item: Partial<RFQItem>) => {
          return {...item, key: item.id}
        }));
        setTimeline(responses[2].data.results);
        setPublications(responses[3].data.results);
        setMRQFileTypes(responses[4].data.results);
        setExtraQuestion(responses[8].data.results);
        const specNames: string[] = responses[6].data.results.map((spec: Partial<MaterialRequestItemSpec>) => spec.spec);
        function onlyUnique(value:string, index: number, self: string[]) {
          return self.indexOf(value) === index;
        }
        setAllSpecs(specNames.filter(onlyUnique).sort());
        axios(`/base/quotation_item/?limit=10000&quotation_ids=${responses[1].data.results.map((q: Partial<Quotation>) => q.id).join(',')}`)
        .then(function (QIresponse) {
          const quotationItems = QIresponse.data.results.map((item: Partial<QuotationItem>) => {
            return {...item, key: item.id}
          });
          setQuotations(responses[1].data.results.map((q: Partial<Quotation>) => {
            q.files = responses[5].data.results.filter((file: Partial<QuotationFile>) => file.quotation === q.id);
            q.items = quotationItems.filter((item: Partial<QuotationItem>) => item.quotation === q.id);
            return {...q, key: q.id}
          }));
        })
        .catch(function (error) {
          console.log(error)
        });
      })).catch(errors => {
        console.log(errors)
      });
    })
    .catch(function (error) {
      console.log(error)
    });
  }, [urlParams, version]);

  const RFQItemColumns: ColumnsType<Partial<RFQItem>> = [
    {
      title: 'Display',
      dataIndex: 'display',
      render: (text, record) => (<EscapeText text={text}/>),
      sorter: (a, b) => {
        if (a.display && b.display) {
          return a.display.localeCompare(b.display);
        }
        return 0;
      },
      width: 300  
    }, 
    {
      title: 'Quantity',
      dataIndex: 'rfq_item_qty',
      render: (text: string) => (numeral(text).format('0,000')),
      width: 150,
      sorter: (a, b) => Number(a.rfq_item_qty) - Number(b.rfq_item_qty),
    },  
    {
      title: 'Unit of Measurment',
      dataIndex: 'rfq_item_unit_of_measurement_display',
      width: 200,
      sorter: (a, b) => {
        if (a.rfq_item_unit_of_measurement_display && b.rfq_item_unit_of_measurement_display) {
          return a.rfq_item_unit_of_measurement_display.localeCompare(b.rfq_item_unit_of_measurement_display);
        }
        return 0;
      }
    },
    {
      title: 'Delivery Date',
      dataIndex: 'rfq_item_delivery_date',
      render: (text: string) => (moment(text, 'YYYY-MM-DD').format('MM/DD/YYYY')),
      width: 150,
      sorter: (a, b) => {
        return Number(moment(a.rfq_item_delivery_date, 'YYYY-MM-DD').format('X')) - Number(moment(b.rfq_item_delivery_date, 'YYYY-MM-DD').format('X'));
      }, 
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      render: (text, record) => (<EscapeText text={text}/>),
      width: 400,
      sorter: (a, b) => {
        if (a.notes && b.notes) {
          return a.notes.localeCompare(b.notes);
        }
        return 0;
      }    
    }
  ];

  const RFQExtraQuestionColumns: ColumnsType<Partial<MaterialRequestExtraQuestion>> = [
    {
      title: 'Question',
      dataIndex: 'question',
      render: (text, record) => (<EscapeText text={text}/>),
      sorter: (a, b) => {
        if (a.question && b.question) {
          return a.question.localeCompare(b.question);
        }
        return 0;
      },
    }, 
  ];

  return (<>
  <div className="bread-crumbs">
    <NavLink to="/rfqs">RFQs</NavLink>
    <i className="material-icons">chevron_right</i>
    <span>RFQ Details</span>
  </div> 
  <div className="object-header">
  {initialLoad ? (<div className="loading padded"><Spin></Spin><span className="msg">loading...</span></div>) :(<>
    <h3><span className="request-title" dangerouslySetInnerHTML={{__html: currentVersion.rfq_title || '--'}}></span></h3>
    <div className="object-meta">   
      <span className="id">{currentVersion.display_doc_number}</span>
      <span className={`request-status ${timelineClass(currentVersion.status_display)}`}>{currentVersion.status_display}</span>   
    </div> 
  </>)}
  </div> 
  {!initialLoad && (<>
    <div className="details-general">
      <div className="details-left full">
        <div className="card general">
          <h3>General Information</h3>
          <RFQDetailsTable
            description={currentVersion.rfq_desc}
            budget={currentVersion.target_budget}
            category={currentVersion.rfq_category_display}
            contact={currentVersion.contact_display}
            notes={currentVersion.notes}
            mrq_header={currentVersion.mrq_header_id}
            mrq_version={currentVersion.mrq_version}
            mrq_doc={currentVersion.mrq_display_doc_number}
            req_exp={currentVersion.quotation_req_exp_date}
            publications={publications}
            all_specs={allSpecs}
            all_files={MRQFileTypes}/>
        </div>
      </div>
    </div>
    { items.length === 0 ? (<div className="card" style={{margin: '8px 16px'}}>
        <h5>No RFQ Items</h5>
      </div>) :
        (<div className="card" style={{paddingBottom: '16px', margin: '8px 16px'}}>
          <h5>RFQ Items<span className="count">{items.length}</span></h5>
          <Table columns={RFQItemColumns} dataSource={items} size="small"/>
        </div>)
      }
    {
      extraQuestion.length === 0 ? (
        <div className="card" style={{margin: '8px 16px'}}>
          <h5>No Extra Question</h5>
        </div>
      ) : (
        <div className="card" style={{paddingBottom: '16px', margin: '8px 16px'}}>
          <h5>Extra Questions<span className="count">{extraQuestion.length}</span></h5>
          <Table columns={RFQExtraQuestionColumns} dataSource={extraQuestion} size="small"/>
        </div>
      )
    }
      { quotations.length === 0 ? (<div className="card" style={{margin: '8px 16px 16px'}}>
          <h5>
            <AddQuotationModal
              rfq={currentVersion}
              quotations={quotations}
              setQuotations={setQuotations}
              user={user}
              fileTypes={MRQFileTypes}
              activeStatus={activeStatus}
              cssClass="no-quotations"
            />
            No Quotations
          </h5>
        </div>) :
        (<div className="card" style={{paddingBottom: '16px', margin: '8px 16px 16px'}}>
          <h5>
            <AddQuotationModal
              rfq={currentVersion}
              quotations={quotations}
              setQuotations={setQuotations}
              fileTypes={MRQFileTypes}
              user={user}
              activeStatus={activeStatus}
            />
            Quotations<span className="count">{quotations.length}</span>
          </h5>
          <Table
            columns={QuotationsColumns}
            dataSource={quotations}
            expandable={{
              expandedRowRender: record => (
                <div className="mat-req-details-rfq-items">
                {record.files && record.files?.length > 0 && (
                  <>
                    <h6><span>Files:</span></h6>
                    {record.files.map((file: Partial<QuotationFile>) => (
                      <a 
                        className="qi-file-download"
                        download={file.file_name}
                        href={`${env.BASE_URL}base/quotation_file/${file.id}/download/`}
                        key={file.id?.toString()}
                        >
                        <DownloadOutlined style={{marginRight: '4px' }}/>
                        {file.file_type_display}
                      </a> 
                    ))}
                  </>
                )}
                {record.items && record.items?.length > 0 && (
                  <>
                    <h6><span>Quotation Items: {record.items?.length}</span></h6>
                    <div className="mat-req-details-rfq-item header">
                      <span className="cell one">Description</span>
                      <span className="cell two">Quantity</span>
                      <span className="cell three">In Stock</span>
                      <span className="cell four">Amount per item</span>
                      <span className="cell five">Lead Time (days)</span>
                      <span className="cell six qi">Delivery Date</span>
                      <span className="cell seven qi">Gross</span>
                    </div>
                    {record.items?.map((item: Partial<QuotationItem>) => (
                      <div key={item.id} className="mat-req-details-rfq-item">
                        <span className="cell one">{item.description}</span>
                        <span className="cell two">{item.rate_only_item ? 'Rate Only' : numeral(item.quantity).format('0,000')}</span>
                        <span className="cell three">{numeral(item.in_stock_qty).format('0,000')}</span>
                        <span className="cell four">{numeral(item.amount_per_item).format('$0,000')}</span>
                        <span className="cell five">{item.lead_time_days}</span>
                        <span className="cell six qi">{moment(item.estimated_delivery_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                        <span className="cell seven qi">{item.rate_only_item ? 'Rate Only' : numeral(item.gross_amount).format('$0,000')}</span>
                      </div>
                    ))}
                  </>
                )}
                </div>
              ),
              rowExpandable: record => {
                if (record.items && record.files) {
                  return record.items.length > 0 || record.files.length > 0;
                }
                return false;
              },
            }}            
            size="small"
          />
        </div>)
      }
  </>)}
</>
);
};

export default RFQDetails;
