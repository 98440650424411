import React from 'react';

interface EscapeProps {
  text: unknown;
}

const EscapeText = ({text}: EscapeProps ) => {
  const val = text as string;
  return (
    <span dangerouslySetInnerHTML={{__html: val}}></span>
  );
}

export default EscapeText;
