import React, {KeyboardEvent, useEffect, useReducer, useState} from 'react';
import { Button, Spin } from 'antd';
import { NavLink } from 'react-router-dom';
import { reducer, pageState } from '../Common/ListPageConsts';
import { timelineClass } from '../Common/TimelineHelper';

import { BestOfferHeaderWithQuotationInfo } from '../Interfaces/BestOfferHeader';
import axios from 'axios';
import moment from 'moment';

const BestOfferList = () => {

	const [count, setCount] = useState<number>(0);
	const [displayStart, setDisplayStart] = useState<number>(0);
	const [displayEnd, setDisplayEnd] = useState<number>(0);
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [bestOfferHeaderList, setBestOfferHeaderList] = useState<Partial<BestOfferHeaderWithQuotationInfo>[]>([]);
	const [nextPage, setNextPage] = useState<boolean>(false);
	const [initialLoad, setInitialLoad] = useState<boolean>(true);

	/** Reducer to manage state of list query parameters */
	let [state, dispatch] = useReducer(reducer, pageState);
 
	/** initial and query param change effect to get list of quotation objects */
	useEffect(() => {
		let queryParams = `?search=${state.term}`;
		if (state.field !== '') {
			queryParams += `&ordering=${state.sort === 'desc' ? '-' : ''}${state.field}`;
		}
		if (state.page > 1) queryParams += `&offset=${(state.page - 1) * 10}`;

		axios(`/base/best_offer_header/with_quotation_info/${queryParams}`)
		.then(function (response) {
			let start_result = 1;
			let end_result = response.data.page_size;
			if (response.data.count === 0) start_result = 0;
			if (response.data.page !== 1) {
				/** we need to add one to the start_results */
				start_result = ((response.data.page - 1) * response.data.page_size) + 1;
				end_result = response.data.page * response.data.page_size;
				if (end_result > response.data.count) end_result = response.data.count;
			}
			if (response.data.count < end_result) end_result = response.data.results.length;
			setInitialLoad(false);
			setDisplayStart(start_result);
			setDisplayEnd(end_result);    
			setCount(response.data.count);
			setBestOfferHeaderList(response.data.results);
			setNextPage(response.data.next !== null);
		})
		.catch(function (error) {
			console.log(error)
		});
	}, [state])    

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			dispatch({
				type: 'searchTerm',
				term: searchTerm,
			});
		}
	};

	return (
		<div className="card quote-list">
			<h3>Best Offer</h3>
			<div className="card-body">
				{!initialLoad && (
					<>
						<Button className="list-reset" onClick={() => {
							setSearchTerm('');
							dispatch({ type: 'reset' });
						}}>
							Reset
						</Button>
						<div id="search-box">
							<label htmlFor="search-field"><i className="material-icons">search</i></label>
							<input 
								type="text"
								id="search-field"
								autoComplete="off"
								placeholder="Search Quotations"
								value={searchTerm}
								onChange={(e) => {setSearchTerm(e.target.value)}}
								onKeyDown={handleKeyDown}
							/>
						</div>
					</>
				)}

				{initialLoad ? (
					<div className="loading"><Spin></Spin><span className="msg">loading...</span></div>
				) : bestOfferHeaderList.length === 0 ? (
						<>
							<span style={{clear: 'both', display: 'block', height: '16px'}}></span>
							<div className="no-results">There are no best offer matching the specified parameters.</div>
						</>
					) : (
						<div>
							<div className="table-display-message">Displaying {displayStart} - {displayEnd} of {count} Best Offer</div>
							<table className="list">
								<thead>
									<tr>
										<th style={{width: '200px'}}>
											<button
												className={`sorter ${state.field === 'display_doc_number' ? 'active' : ''} ${state.sort}`}
												onClick={() => { dispatch({ type: 'sortByField', fieldName: 'display_doc_number' }); }}
											>
												ID
												<div className="indicators">
													<i className="material-icons">arrow_drop_up</i>
													<i className="material-icons">arrow_drop_down</i>
												</div>
											</button>              
										</th>
										<th style={{width: '200px'}}>
											<button
												className={`sorter ${state.field === 'quotation_display_doc_number' ? 'active' : ''} ${state.sort}`}
												onClick={() => { dispatch({ type: 'sortByField', fieldName: 'quotation_display_doc_number' }); }}
											>
												Quotation ID
												<div className="indicators">
													<i className="material-icons">arrow_drop_up</i>
													<i className="material-icons">arrow_drop_down</i>
												</div>
											</button>              
										</th>
										<th style={{width: '150px'}}>
											<button
												className={`sorter ${state.field === 'status__name' ? 'active' : ''} ${state.sort}`}
												onClick={() => { dispatch({ type: 'sortByField', fieldName: 'status__name' }); }}
											>
												Status
												<div className="indicators">
													<i className="material-icons">arrow_drop_up</i>
													<i className="material-icons">arrow_drop_down</i>
												</div>
											</button>              
										</th>
										<th>
											<button 
												className={`sorter ${state.field === 'notes' ? 'active' : ''} ${state.sort}`}
												onClick={() => { dispatch({ type: 'sortByField', fieldName: 'notes' }); }}
											>
												Notes
												<div className="indicators">
													<i className="material-icons">arrow_drop_up</i>
													<i className="material-icons">arrow_drop_down</i>
												</div>
											</button>              
										</th>
										<th>
											<button 
												className={`sorter ${state.field === 'response_deadline' ? 'active' : ''} ${state.sort}`}
												onClick={() => { dispatch({ type: 'sortByField', fieldName: 'response_deadline' }); }}
											>
												Response Deadline
												<div className="indicators">
													<i className="material-icons">arrow_drop_up</i>
													<i className="material-icons">arrow_drop_down</i>
												</div>
											</button>
										</th>
										<th>
											<button 
												className={`sorter ${state.field === 'responsed_date' ? 'active' : ''} ${state.sort}`}
												onClick={() => { dispatch({ type: 'sortByField', fieldName: 'responsed_date' }); }}
											>
												Responsed Date
												<div className="indicators">
													<i className="material-icons">arrow_drop_up</i>
													<i className="material-icons">arrow_drop_down</i>
												</div>
											</button>   
										</th>
										<th>
											<button 
												className={`sorter ${state.field === 'contact' ? 'active' : ''} ${state.sort}`}
												onClick={() => { dispatch({ type: 'sortByField', fieldName: 'contact' }); }}
											>
												Point of Contact
												<div className="indicators">
													<i className="material-icons">arrow_drop_up</i>
													<i className="material-icons">arrow_drop_down</i>
												</div>
											</button>              
										</th>
									</tr>
								</thead>
								<tbody>
									{bestOfferHeaderList.map(
										({id, display_doc_number, quotation_header, quotation_display_doc_number, quotation_version, status_display, notes, response_deadline, response_date, contact_display}) => (
											<tr key={id?.toString()}>
												<td><NavLink to={`/quotation/${quotation_header}/?version=${quotation_version}`}>{display_doc_number}</NavLink></td>
												<td><NavLink to={`/quotation/${quotation_header}/?version=${quotation_version}`}>{quotation_display_doc_number}</NavLink></td>
												<td style={{width: '100px'}}>
													<span className={`request-status ${timelineClass(status_display)}`}>{status_display}</span>
												</td>
												<td>{notes}</td>
												<td>{moment(response_deadline, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
												<td>{response_date !== null ? moment(response_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '--'}</td>
												<td>{contact_display}</td>
											</tr>
										)
									)}
								</tbody>
							</table>
							<div className="pagination">
								<button type="button"
									className={state.page < 2 ? "disabled" : ""}
									disabled={state.page < 2}
									onClick={() => { dispatch({ type: 'previousPage' }); }}
								>
									<i className="material-icons">chevron_left</i>
								</button>
								<button type="button"
									className={nextPage === false ? "disabled" : ""}
									onClick={() => { dispatch({ type: 'nextPage' }); }}
									disabled={nextPage === false}
								>
									<i className="material-icons">chevron_right</i>
								</button>
							</div>
						</div>
					)
				}
			</div>
		</div>
	);
}

export default BestOfferList;