import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, DatePicker, Form, Input, Modal, Select, Upload } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';

import { useResetFormOnCloseModal } from '../FormHelpers'
import CurrentUser from '../../Interfaces/CurrentUser';
import FileObjectProps from '../../Interfaces/FileObjectProps';
import Quotation from '../../Interfaces/Quotation';
import RFQ from '../../Interfaces/RFQ';
import Status from '../../Interfaces/Status';
import axios, { AxiosResponse } from 'axios';
import MaterialRequestFile from '../../Interfaces/MaterialRequestFile';
import QuotationFile from '../../Interfaces/QuotationFile';
import UserSupplier from '../../Interfaces/UserSupplier';
import Supplier from '../../Interfaces/Supplier';

const { TextArea } = Input;

interface AddQuotationModalProps { 
  rfq: Partial<RFQ>,
  cssClass?: string,
  quotations: Partial<Quotation>[],
  setQuotations: Dispatch<SetStateAction<Partial<Quotation>[]>>;
  user: Partial<CurrentUser>;
  fileTypes: Partial<MaterialRequestFile>[]; 
  activeStatus: Partial<Status>;
}

const AddQuotationModal = ({
  cssClass,
  fileTypes,
  quotations,
  rfq,
  setQuotations,
  user,
  activeStatus,
}: AddQuotationModalProps) => {

  const [isQuoteModalVisible, setIsQuoteModalVisible] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [payTerm, setPayTerm] = useState<string>('');
  const [allSuppliers, setAllSuppliers] = useState<Partial<Supplier>[]>([]);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const showQuotationModal = (): void => setIsQuoteModalVisible(true);
  const hideQuotationModal = (): void => {
    setIsQuoteModalVisible(false);
    setConfirmLoading(false);
  };

  const onOk = (): void => form.submit();
  const onFinish = (values: any): void => {
    setConfirmLoading(true);
    const newQuotation: Partial<Quotation> = {};
    newQuotation.rfq = rfq.id;
    newQuotation.contact = user.person_id;
    newQuotation.payment_term = values.payment_term;
    if (values.other_payment_terms) newQuotation.other_payment_terms = values.other_payment_terms;
    newQuotation.notes = values.notes;
    newQuotation.expiration_date = values.expiration.format('YYYY-MM-DD');
    newQuotation.estimated_delivery_date = values.estimated_delivery.format('YYYY-MM-DD');
    if (!values.supplier && user.suppliers) {
      newQuotation.supplier = user.suppliers[0]?.id;
    } else {
      newQuotation.supplier = values.supplier;
    }
    axios({
      method: 'POST',
      url: '/base/quotation_header/',
      data: {
        status: activeStatus.id,
        is_deleted: false
      },
    })
    .then(function (headerResponse) {
      newQuotation.quotation_header = headerResponse.data.id;
      axios({
        method: 'POST',
        url: '/base/quotation/',
        data: newQuotation,
      })
      .then(function (response) {
        response.data.items = [];
        response.data.key = response.data.id;
        const quoteFiles: Partial<QuotationFile>[] = []
        for (const [key, value] of Object.entries(values)){
          if (key.includes('file_')) {
            const fileTypeId = Number(key.replace('file_', ''));
            const inputData = value as Array<Partial<FileObjectProps>>;
            if (inputData[0].originFileObj) {
              quoteFiles.push({
                content_type: inputData[0].type,
                file_name: inputData[0].name,
                file_object: inputData[0].originFileObj,
                file_type: fileTypeId,
                generate_upload_url: true,
                required_flag: false,
                status: activeStatus.id,
                quotation: response.data.id
              })
            }
          }
        }
        const calls: Promise<AxiosResponse>[] = [];
        quoteFiles.forEach((fileData) => {
          calls.push(axios({
            method: 'POST', 
            url: '/base/quotation_file/', 
            data: fileData,
          }));
        });
        axios.all(calls).then(axios.spread((...responses) => {
          const uploads: Promise<AxiosResponse>[] = [];
          responses.forEach((res, index) => {
            const fileData = quoteFiles[index];
            uploads.push(
              axios.put(res.data.signed_upload_url, fileData.file_object, {
                headers: {
                  'Content-Type': fileData.content_type || ''
                }
              })
            );
          });
          axios.all(uploads).then(axios.spread((...uploadResponses) => {
            hideQuotationModal();
            response.data.key = response.data.id;
            setQuotations([response.data, ...quotations]);
            navigate(`/quotation/${headerResponse.data.id}/?version=${response.data.version}`);
          })).catch(errors => {
            console.log(errors)
          });
        })).catch(errors => {
          console.log(errors)
        });
      })
      .catch(function (error) {
        console.log(error)
      });
    })
    .catch(function (error) {
      console.log(error)
    });
  };

  useEffect(() => {
    axios.all([
      axios(`/reference/supplier/?status__name=Active&ordering=supplier_name&limit=10000`),
    ]).then(axios.spread((...responses) => {
      setAllSuppliers(responses[0].data.results);
    })).catch(errors => {
      console.log(errors)
    });
  }, []);

  useResetFormOnCloseModal({
    form,
    visible: isQuoteModalVisible,
  });

  const dummyRequest = ({ file, onSuccess }: UploadRequestOption<any>) => {
    setTimeout(() => {
      if (onSuccess) onSuccess("ok");
    }, 0);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) return e;
    return e?.fileList;
  };

  const payTermSelection = (value: string) => {
    setPayTerm(value);
  }

  return(
    <>
      <Button
        className={`add-quotation-open ${cssClass}`}
        onClick={showQuotationModal}
        type="primary"><PlusOutlined style={{margin: '0 4px 0 0'}}/>Quotation</Button>
      <Modal 
        confirmLoading={confirmLoading}
        title="Add Quotation"
        visible={isQuoteModalVisible}
        onOk={onOk}
        okText="Submit"
        onCancel={hideQuotationModal}
        keyboard={false}
        maskClosable={false}
        width="700px"
        style={{ top: 20 }}>
        <Form 
          layout="vertical"
          name="addQuotationForm"
          form={form}
          onFinish={onFinish}
          initialValues={{
            lump_sum_discount: 0,
          }}>
          <Alert
            message="For your information: You will be able to add Quotation Items from the details page of your newly created Quotation."
            type="info"
            style={{marginBottom: '16px'}}
          />
          <div className="split-form">
            <div className="split-form-section">
              <Form.Item
                label="Estimated delivery date"
                name="estimated_delivery"
                rules={[{ required: true, message: 'Please supply an estimated delivery date.' }]}>
                <DatePicker style={{width: '100%'}}/>
              </Form.Item>
              <Form.Item
                label="Expiration date"
                name="expiration"
                rules={[{ required: true, message: 'Please supply an expriation date.' }]}>
                <DatePicker style={{width: '100%'}}/>
              </Form.Item>
            </div>
            <div className="split-form-section right">
              { user.suppliers && user.suppliers.length > 1 && (
                <Form.Item label="Supplier" name="supplier" rules={[{ required: true, message: 'Please select a supplier.' }]}>
                  <Select 
                    allowClear
                    placeholder="Select supplier"
                    style={{width: '100%'}}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                    filterSort={(optionA, optionB) =>
                      (optionA!.children as unknown as string)
                        .toLowerCase()
                        .localeCompare((optionB!.children as unknown as string).toLowerCase())
                    }
                  >
                    {user.suppliers.map((sup: UserSupplier) => (
                      <Select.Option key={sup.id.toString()} value={sup.id}>{sup.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              { user.suppliers && user.suppliers.length === 0 && (
                <Form.Item label="Supplier" name="supplier" rules={[{ required: true, message: 'Please select a supplier.' }]}>
                  <Select 
                    allowClear
                    placeholder="Select supplier"
                    style={{width: '100%'}}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                    filterSort={(optionA, optionB) =>
                      (optionA!.children as unknown as string)
                        .toLowerCase()
                        .localeCompare((optionB!.children as unknown as string).toLowerCase())
                    }
                  >
                    {allSuppliers.map((sup:Partial<Supplier>) => (
                      <Select.Option key={sup.id?.toString()} value={sup.id}>{sup.supplier_name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label="Payment Term"
                name="payment_term"
                rules={[{ required: true, message: 'Please supply a payment term.' }]}>
                <Select 
                  allowClear
                  placeholder="Select payment term"
                  style={{width: '100%'}}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                  filterSort={(optionA, optionB) =>
                    (optionA!.children as unknown as string)
                      .toLowerCase()
                      .localeCompare((optionB!.children as unknown as string).toLowerCase())
                  }
                  onChange={payTermSelection} 
                >
                  <Select.Option value="other">Other</Select.Option>
                  <Select.Option value="term 15">term 15</Select.Option>
                  <Select.Option value="term 30">term 30</Select.Option>
                  <Select.Option value="term 45">term 45</Select.Option>
                  <Select.Option value="term 60">term 60</Select.Option>
                  <Select.Option value="term 90">term 90</Select.Option>
                </Select>
              </Form.Item>
             { payTerm === 'other' && (
                <Form.Item label="Other Payment Terms" name="other_payment_terms">
                  <Input placeholder="Provide other payment terms" />
                </Form.Item>
              )}
            </div>
          </div>
          <Form.Item label="Notes" name="notes">
            <TextArea
              rows={3} 
              placeholder="Please provide any other notes"/>
          </Form.Item> 
          {fileTypes.length > 0 && (<>
            <span className="quoteitem-spec-file-header">Requested Files:</span>
            <div className="fifty-split">
              { fileTypes.map((fileType: Partial<MaterialRequestFile>) => (
                <div key={fileType.id?.toString()}>
                  <Form.Item
                    name={`file_${fileType.file_type}`}
                    label={fileType.file_type_display}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true, message: 'Please supply a file.' }]}
                  >
                    <Upload maxCount={1} customRequest={dummyRequest} accept="image/*,.pdf,.txt">
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </Upload>
                  </Form.Item>
                </div>
              ))}
            </div>
          </>)}
        </Form>
      </Modal>
    </>
  );
}

export default AddQuotationModal;
