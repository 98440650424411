import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Alert, Button, DatePicker, Form, Input, InputNumber, Select, Upload } from 'antd';
import { PaperClipOutlined, UploadOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import 'antd/dist/antd.css';

import FileObjectProps from '../Interfaces/FileObjectProps';
import Quotation from '../Interfaces/Quotation';
import QuotationFile from '../Interfaces/QuotationFile';
import axios, { AxiosResponse }  from 'axios';
import moment from 'moment';
import CurrentUser from '../Interfaces/CurrentUser';
import UserSupplier from '../Interfaces/UserSupplier';
import Supplier from '../Interfaces/Supplier';

const { TextArea } = Input;

const env = (window as { [key: string]: any })["_env_"];

interface QEditFormProps {
  user: Partial<CurrentUser>;
}

const QuotationEditForm = ({user}: QEditFormProps) => {

  const [quote, setQuote] = useState<Partial<Quotation>>({});
  const [files, setFiles] = useState<Partial<QuotationFile>[]>([]);
  const [allSuppliers, setAllSuppliers] = useState<Partial<Supplier>[]>([]);
  const [payTerm, setPayTerm] = useState<string>('');
  const [form] = Form.useForm();
  const urlParams = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.all([
      axios(`/base/quotation/${urlParams.quotationId}/`),
      axios(`/base/quotation_file/?limit=10000&quotation=${urlParams.quotationId}&ordering=file_type__file_type_name`),
      axios(`/reference/supplier/?status__name=Active&ordering=supplier_name&limit=10000`),
    ]).then(axios.spread((...responses) => {
      const quote: Partial<Quotation> = responses[0].data;
      setQuote(quote);
      setFiles(responses[1].data.results);
      setAllSuppliers(responses[2].data.results);
      const QValues: Partial<Quotation> = {
        estimated_delivery: moment(quote.estimated_delivery_date, 'YYYY-MM-DD'),
        expiration: moment(quote.expiration_date, 'YYYY-MM-DD'),
        id: quote.id,
        lump_sum_discount: quote.lump_sum_discount,
        notes: quote.notes,
        payment_term: quote.payment_term,
        other_payment_terms:  quote.other_payment_terms,
        supplier: quote.supplier
      }
      if (quote.payment_term) setPayTerm(quote.payment_term);
      form.setFieldsValue(QValues);
    })).catch(errors => {
      console.log(errors)
    });
  }, [urlParams, form]);

  const onFinish = (values: any): void => {
    console.log(values)
    const updatedQuotation: Partial<Quotation> = {}; 
    updatedQuotation.payment_term = values.payment_term;
    if (values.other_payment_terms) updatedQuotation.other_payment_terms = values.other_payment_terms;
    updatedQuotation.lump_sum_discount = values.lump_sum_discount;
    updatedQuotation.notes = values.notes;
    updatedQuotation.expiration_date = values.expiration.format('YYYY-MM-DD');
    updatedQuotation.estimated_delivery_date = values.estimated_delivery.format('YYYY-MM-DD');
    updatedQuotation.notes = updatedQuotation.notes === undefined ? '' : updatedQuotation.notes;
    updatedQuotation.supplier = values.supplier;

    const quoteFileData: Partial<QuotationFile>[] =  [];
    for (const [key, value] of Object.entries(values)){
      if (key.includes('file_')) {
        const fileTypeId = Number(key.replace('file_', ''));
        const inputData = value as Array<Partial<FileObjectProps>>;
        if (inputData && inputData[0].originFileObj) {
          quoteFileData.push({
            file_name: inputData[0].name,
            file_object: inputData[0].originFileObj,
            content_type: inputData[0].type,
            generate_upload_url: true,
            id: fileTypeId,
          })
        }
      }
    }
    const calls: Promise<AxiosResponse>[] = [
      axios({method: 'PATCH', url: `/base/quotation/${urlParams.quotationId}/`, data: updatedQuotation})
    ]

    for (const file of quoteFileData) {
      const matchingFile = files.find((existingFile: Partial<QuotationFile>) => existingFile.file_type === file.id);
      if (matchingFile) {
        delete file.id;
        calls.push(axios({
          method: 'PATCH',
          url: `/base/quotation_file/${matchingFile.id}/`,
          data: file
        }));
      }
    }

    axios.all(calls).then(axios.spread((...responses) => {
      const uploads: Promise<AxiosResponse>[] = [];
      responses.forEach((response, index) => {
        if (index > 0) {
          const fileData = quoteFileData[index - 1];
          uploads.push(
            axios.put(response.data.signed_upload_url, fileData.file_object, {
              headers: {
                'Content-Type': fileData.content_type || ''
              }
            })
          );
        }
      });
      axios.all(uploads).then(axios.spread((...uploadResponses) => {
        navigate(`/quotation/${quote.quotation_header}/?version=${quote.version}`)
      })).catch(errors => {
        console.log(errors)
      });
    })).catch(errors => {
      console.log(errors)
    });
  }

  const dummyRequest = ({ file, onSuccess }: UploadRequestOption<any>) => {
    setTimeout(() => {
      if (onSuccess) onSuccess("ok");
    }, 0);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) return e;
    return e?.fileList;
  };

  const payTermSelection = (value: string) => {
    setPayTerm(value);
  }  

  return (<div className="card request-form">
    <h3>Edit Quotation</h3>
    <div className="card-body">
      <Form 
        layout="vertical"
        name="addQuotationForm"
        form={form}
        onFinish={onFinish}
        initialValues={{
          id: '',
          estimated_deliver: '',
          expiration: '',
          payment_term: '',
          notes: '',
          lump_sum_discount: 0,
          supplier: ''
        }}>
        <div className="split-form">
          <div className="split-form-section">
            <Form.Item name="id" noStyle><input type="hidden"/></Form.Item>
            <Form.Item
              label="Estimated delivery date"
              name="estimated_delivery"
              rules={[{ required: true, message: 'Please supply an estimated delivery date.' }]}>
              <DatePicker style={{width: '100%'}}/>
            </Form.Item>
            <Form.Item
              label="Expiration date"
              name="expiration"
              rules={[{ required: true, message: 'Please supply an expriation date.' }]}>
              <DatePicker style={{width: '100%'}}/>
            </Form.Item>
            <Form.Item label="Supplier" name="supplier" rules={[{ required: true, message: 'Please select a supplier.' }]}>
              <Select 
                allowClear
                placeholder="Select supplier"
                style={{width: '100%'}}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                  (optionA!.children as unknown as string)
                    .toLowerCase()
                    .localeCompare((optionB!.children as unknown as string).toLowerCase())
                }
              >
                {user.suppliers && user.suppliers.length > 0 && user.suppliers.map((sup: UserSupplier) => (
                  <Select.Option key={sup.id.toString()} value={sup.id}>{sup.name}</Select.Option>
                ))}
                {user.suppliers && user.suppliers.length === 0 && allSuppliers.map((sup: Partial<Supplier>) => (
                  <Select.Option key={sup.id?.toString()} value={sup.id}>{sup.supplier_name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="split-form-section right">
            <Form.Item
              label="Payment Term"
              name="payment_term"
              rules={[{ required: true, message: 'Please supply a payment term.' }]}>
              <Select 
                allowClear
                placeholder="Select payment term"
                style={{width: '100%'}}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                  (optionA!.children as unknown as string)
                    .toLowerCase()
                    .localeCompare((optionB!.children as unknown as string).toLowerCase())
                }
                onChange={payTermSelection} 
              >
                <Select.Option value="other">Other</Select.Option>
                <Select.Option value="term 15">term 15</Select.Option>
                <Select.Option value="term 30">term 30</Select.Option>
                <Select.Option value="term 45">term 45</Select.Option>
                <Select.Option value="term 60">term 60</Select.Option>
                <Select.Option value="term 90">term 90</Select.Option>
              </Select>
            </Form.Item>
            { payTerm === 'other' && (
                <Form.Item label="Other Payment Terms" name="other_payment_terms">
                  <Input placeholder="Provide other payment terms" />
                </Form.Item>
              )}
            <Form.Item label="Lump Sum Discount" name="lump_sum_discount">
              <InputNumber
                step="100"
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                style={{width: '100%'}}/>
            </Form.Item>
          </div>
        </div>
        <Form.Item label="Notes" name="notes">
          <TextArea
            rows={3} 
            placeholder="Please provide any other notes"/>
        </Form.Item> 
        {files.length > 0 && (<>
          <span className="quoteitem-spec-file-header">Requested Files:</span>
          <Alert
            message="If you are satisfied with currently uploaded files, you do not need to attach new ones."
            type="info"
            style={{marginBottom: '10px'}}
          />
          <div className="fifty-split">
          { files.map((fileType: Partial<QuotationFile>) => (
            <div key={fileType.id?.toString()}>
              <Form.Item
                name={`file_${fileType.file_type}`}
                label={fileType.file_type_display}
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload maxCount={1} customRequest={dummyRequest} accept="image/*,.pdf,.txt">
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
              <span>
                <em style={{fontWeight: '500'}}>currently uploaded:</em>
                <a 
                  download={fileType.file_name}
                  href={`${env.BASE_URL}base/quotation_item_file/${fileType.id}/download/`}
                  >
                  <PaperClipOutlined style={{margin: '0 3px 0 5px', color: '#777' }}/>
                  {fileType.file_name}
                </a>
              </span>
            </div>
          ))}
          </div>
        </>)}
        <div className="center-button">
          <Button>
            <NavLink to={`/quotation/${quote.quotation_header}/?version=${quote.version}`}>Cancel</NavLink>
          </Button>
          <Form.Item>
            <Button htmlType="submit" type="primary">Submit</Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  </div>
  );
};

export default QuotationEditForm;
