import React, { Dispatch, SetStateAction, useState } from 'react';
import { Alert, Button, DatePicker, Form, Input, InputNumber, Modal, Select, Switch, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import { useResetFormOnCloseModal } from '../FormHelpers';
import FileObjectProps from '../../Interfaces/FileObjectProps';
import MaterialRequestItem from '../../Interfaces/MaterialRequestItem';
import MaterialRequestItemSpec from '../../Interfaces/MaterialRequestItemSpec';
import QuotationItemSpec from '../../Interfaces/QuotationItemSpec';
import Quotation from '../../Interfaces/Quotation';
import QuotationItem from '../../Interfaces/QuotationItem';
import RFQItem from '../../Interfaces/RFQItem';
import Status from '../../Interfaces/Status';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import QuotationItemFile from '../../Interfaces/QuotationItemFile';

const { TextArea } = Input;
const { Option } = Select;

interface AddQuoteItemModalProps { 
  activeStatus: Partial<Status>;
  cssClass?: string,
  items: Partial<QuotationItem>[];
  quotation: Partial<Quotation>;
  rfqItems: Partial<RFQItem>[];
  setItems: Dispatch<SetStateAction<Partial<QuotationItem>[]>>;
  updateStatus: Function;
}

const AddQuotationItemModal = ({
  activeStatus, 
  cssClass = '',
  items,
  quotation,
  rfqItems,
  setItems,
  updateStatus,
}: AddQuoteItemModalProps) => {

  let [isQuoteItemVisible, setIsQuoteItemVisible] = useState<boolean>(false);
  let [confirmLoading, setConfirmLoading] = useState(false);
  let [selectedRFQItem, setSelectedRFQItem] = useState<Partial<RFQItem>>({});
  let [loadingSpecs, setLoadingSpecs] = useState(false);
  let [specs, setSpecs] = useState<Partial<MaterialRequestItemSpec>[]>([]);
  let [materialRequestItem, setMaterialRequestItem] = useState<Partial<MaterialRequestItem>>({});
  let [rateOnlyItem, setRateOnlyItem] = useState<boolean>(false);

  const existingIds: (number|undefined)[] = items.map((item: Partial<QuotationItem>) => item.rfq_item)
  const [form] = Form.useForm();
  const showQuoteItemModal = (): void => setIsQuoteItemVisible(true);
  const hideQuoteItemModal = (): void => {
    setIsQuoteItemVisible(false);
    setConfirmLoading(false);
    /** Clear the ancillary form objects */
    setMaterialRequestItem({});
    setSelectedRFQItem({});
    setSpecs([]);
    setRateOnlyItem(false);
  }
  const onOk = (): void => form.submit();

  const onFinish = (values: any): void => {
    setConfirmLoading(true);
    const newQI: Partial<QuotationItem> = {};
    newQI.quotation = quotation.id;
    newQI.status = activeStatus.id;
    newQI.rfq_item = values.rfq_item;
    newQI.description = values.description;
    newQI.rate_only_item = values.rate_only_item;
    newQI.quantity = values.quantity;
    newQI.in_stock_qty = values.in_stock_qty;
    newQI.amount_per_item = values.amount_per_item;
    newQI.notes = values.notes ? values.notes : '';
    newQI.line_discount = values.line_discount;
    newQI.lead_time_days = values.lead_time_days;
    newQI.estimated_delivery_date = values.estimated_delivery.format('YYYY-MM-DD');
    newQI.category = materialRequestItem.material_item_category_id;
    newQI.unit_of_measurement = materialRequestItem.material_item_unit_of_measurement_id;
    axios({method: 'POST', url: '/base/quotation_item/', data: newQI})
    .then(function (qiResponse) {
      const {
        rate_only_item,
        amount_per_item,
        description,
        estimated_delivery,
        in_stock_qty,
        lead_time_days,
        line_discount,
        notes,
        quantity,
        rfq_item,
        ...specValues
      } = values;
      const newQISpecs: Partial<QuotationItemSpec>[] = [];
      const newQIFiles: Partial<QuotationItemFile>[] = [];
      for (const [key, value] of Object.entries(specValues)){
        if (key.includes('file_')) {
          const fileTypeId = Number(key.replace('file_', ''));
          const inputData = value as Array<Partial<FileObjectProps>>;
          if (inputData[0].originFileObj) {
            newQIFiles.push({
              content_type: inputData[0].type,
              file_name: inputData[0].name,
              file_object: inputData[0].originFileObj,
              file_type: fileTypeId,
              generate_upload_url: true,
              required_flag: false,
              status: activeStatus.id,
              quotation_item: qiResponse.data.id,
            })
          }
        } else {
          newQISpecs.push({
            item: qiResponse.data.id,
            material_request_item_spec: Number(key),
            spec_required: true,
            spec_response: String(value),
            spec_note: '',
            status: activeStatus.id,
          });
        }
      }
      const calls: Promise<AxiosResponse>[] = [
        axios({method: 'POST', url: '/base/quotation_item_spec/bulk_load/', data: newQISpecs})
      ]
      newQIFiles.forEach((fileData) => {
        calls.push(axios({
          method: 'POST', 
          url: '/base/quotation_item_file/',
          data: fileData
        }));
      });
      axios.all(calls).then(axios.spread((...responses) => {
        const uploads: Promise<AxiosResponse>[] = [];
        responses.forEach((res, index) => {
          if (index > 0) {
            const fileData = newQIFiles[index - 1];
            uploads.push(
              axios.put(res.data.signed_upload_url, fileData.file_object, {
                headers: {
                  'Content-Type': fileData.content_type || ''
                }
              })
            );
          }
        });
        axios.all(uploads).then(axios.spread((...uploadResponses) => {
          axios.all([
            axios(`/base/quotation_item_spec/?limit=10000&item=${qiResponse.data.id}&ordering=material_request_item_spec__seq`),
            axios(`/base/quotation_item_file/?limit=10000&quotation_item=${qiResponse.data.id}&ordering=file_type__file_type_name`)
          ]).then(axios.spread((...lookupResponses) => {
            qiResponse.data.key = qiResponse.data.id;
            qiResponse.data.specs = lookupResponses[0].data.results;
            qiResponse.data.files = lookupResponses[1].data.results;
            setItems([qiResponse.data, ...items]);
            updateStatus();
            hideQuoteItemModal();
          })).catch(errors => {
            console.log(errors)
          });
        })).catch(errors => {
          console.log(errors)
        });
      })).catch(errors => {
        console.log(errors)
      });
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  useResetFormOnCloseModal({
    form,
    visible: isQuoteItemVisible,
  });

  const rfqSelection = (value: number) => {
    const matchingRFQItem = rfqItems.find((item: Partial<RFQItem>) => item.id === value);
    if (matchingRFQItem) {
      setSelectedRFQItem(matchingRFQItem);
      setLoadingSpecs(true);
      axios.all([
        axios(`/base/material_request_item_spec/?limit=1000&ordering=seq&material_request_item=${matchingRFQItem.material_request_item}`),
        axios(`/base/material_request_item/${matchingRFQItem.material_request_item}/`),
      ]).then(axios.spread((...responses) => {
        setLoadingSpecs(false);
        setSpecs(responses[0].data.results);
        setMaterialRequestItem(responses[1].data);

        const defaultQISettings: Partial<QuotationItem> = {
          quantity: matchingRFQItem.rfq_item_qty,
          estimated_delivery: moment(quotation.estimated_delivery_date,  'YYYY-MM-DD')
        }
        form.setFieldsValue(defaultQISettings);
      })).catch(errors => {
        console.log(errors)
      });    
    }
  };

  return (<>
    {rfqItems.length > existingIds.length && (<Button
      className={`mat-quotation-items-open ${cssClass}`}
      onClick={showQuoteItemModal}
      type="primary"><PlusOutlined style={{margin: '0 4px 0 0'}}/>Quotation Items</Button>)}
    <Modal 
      confirmLoading={confirmLoading}
      title="Add Quotation Item"
      visible={isQuoteItemVisible}
      onOk={onOk}
      okText="Submit"
      onCancel={hideQuoteItemModal}
      keyboard={false}
      maskClosable={false}
      width="98%"
      style={{ top: 20 }}>
      <Form 
        layout="vertical"
        name="quotationItemForm"
        form={form}
        onFinish={onFinish}
        initialValues={{
          amount_per_item: 0,
          lead_time_days: 0,
          line_discount: 0,
          quantity: 0,
        }}>
        <div className="split-form">
          <div className="split-form-section">
            <Form.Item
              label="RFQ Item"
              name="rfq_item"
              rules={[{ required: true, message: 'Please select an RFQ item.' }]}>
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                filterSort={(optionA, optionB) =>
                  (optionA!.children as unknown as string)
                    .toLowerCase()
                    .localeCompare((optionB!.children as unknown as string).toLowerCase())
                }
                onChange={rfqSelection}
              >
                {rfqItems.filter((item: Partial<RFQItem>) => !existingIds.includes(item.id)).map((item: Partial<RFQItem>) => (
                  <Option key={item.id} value={item.id}>{item.display}</Option>
                ))}
              </Select>
            </Form.Item>  
            <Form.Item
                label="Item Description"
                name="description" 
                rules={[{ required: true, message: 'Please supply an item desciption.' }]}>
              <TextArea
                rows={3} 
                placeholder="Please provide an item description"/>
            </Form.Item> 
            <div className="fifty-split">
              <div>
                <Form.Item 
                  label="Rate Only"
                  name="rate_only_item"
                  valuePropName="checked">
                  <Switch
                    onChange={() => {
                      setRateOnlyItem(!rateOnlyItem);
                    }}/>
                </Form.Item> 
              </div>
              <div>
                <Form.Item 
                  label="Quantity"
                  name="quantity"
                  rules={[{ required: (rateOnlyItem ? false : true), message: 'Please enter a quantity.' }]}>
                  <InputNumber
                    style={{ width: '100%'}}
                    step="100"
                    placeholder="Enter quantity"/>
                </Form.Item> 
              </div>
              <div>
                <Form.Item 
                  label="In stock quantity"
                  name="in_stock_qty">
                  <InputNumber
                    style={{ width: '100%'}}
                    step="100"
                    placeholder="Enter quantity"/>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Amount per item"
                  name="amount_per_item"
                  rules={[{ required: true, message: 'Please supply an amount per item.' }]}>
                  <InputNumber
                    step="100"
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%'}}
                    placeholder="Enter amount per item"/>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Line discount"
                  name="line_discount"
                  rules={[{ required: true, message: 'Please supply a line dicount.' }]}>
                  <InputNumber
                    step="100"
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                    style={{ width: '100%'}}
                    placeholder="Enter discount"/>
                </Form.Item> 
              </div>
              <div>
                <Form.Item 
                  label="Lead time in days"
                  name="lead_time_days"
                  rules={[{ required: true, message: 'Please enter a lead time.' }]}>
                  <InputNumber
                    style={{ width: '100%'}}
                    step="1"
                    placeholder="Enter lead time in days"/>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Estimated delivery date"
                  name="estimated_delivery"
                  rules={[{ required: true, message: 'Please supply an estimated delivery date.' }]}>
                  <DatePicker style={{width: '100%'}}/>
                </Form.Item> 
              </div>
            </div>
            <Form.Item label="Notes" name="notes">
              <TextArea
                rows={3} 
                placeholder="Please provide any other notes"/>
            </Form.Item>   
          </div>
          <div className="split-form-section right">
            { !selectedRFQItem.id && (<Alert message="Once you select an RFQ Item, associated spec and file input fields will display" type="info"/>)}
            { loadingSpecs && (<div className="loading padded"><Spin></Spin><span className="msg">loading specs and files...</span></div>)}
            { !loadingSpecs && specs.length > 0 && (<>
              <span className="quoteitem-spec-file-header">Specs:</span>
              <div className="fifty-split">
                { specs.map((spec: Partial<MaterialRequestItemSpec>) => (
                  <div key={spec.id?.toString()}>
                    <Form.Item label={spec.spec} name={spec.id}>
                      <Input placeholder={spec.expected_value}/>
                    </Form.Item>
                  </div>
                ))}
              </div>
            </>)}
          </div>
        </div>
      </Form>
    </Modal>
  </>);
};

export default AddQuotationItemModal;
