import React from 'react';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from 'file-saver';

import RFQPublication from '../../Interfaces/RFQPublication';
import MaterialRequestFile from '../../Interfaces/MaterialRequestFile';

const env = (window as { [key: string]: any })["_env_"];

interface RFQDetailTableProps {
  description: string|undefined,
  budget: number|undefined,
  category: string|undefined,
  contact: string|undefined,
  mrq_header: number|undefined,
  mrq_version: string|undefined,
  mrq_doc: string|undefined,
  notes: string|undefined,
  publications?: Partial<RFQPublication>[],
  req_exp?:string|null,
  all_specs?: string[],
  all_files?: Partial<MaterialRequestFile>[],
}


const RFQDetailsTable = ({all_files, all_specs, description, budget, category, contact, notes, mrq_doc, mrq_header, mrq_version, publications = [], req_exp}: RFQDetailTableProps) => {

  const getFile = (id: number|undefined, name: string|undefined|null, type: string) => {
    const url = `${env.BASE_URL}base/${type}/${id}/download/`;
    const fileName: string =  typeof name === 'string' ? name : '';
    axios.get(url, { responseType: "blob", timeout: 1000000})
    .then(function (response) {
      saveAs(response.data, fileName);
    })
    .catch(function (error) {
      console.log(error)
    });
  } 

  return (
    <table className="details-table new">
      <tbody>
        <tr>
          <td><label>Point of Contact:</label>{contact}</td>
          <td><label>Target Budget:</label>{numeral(budget).format('$0,000.00')}</td>
          <td>
            <label>RFQ End Date:</label>
            { publications[0] ? (<>{publications[0].publication_end}</>) : ('--') }
          </td>
        </tr>           
        <tr>
          <td><label>Category:</label>{category}</td>
          <td dangerouslySetInnerHTML={{__html: `<label>Description:</label>${description ? description : '--'}`}}></td>
          <td dangerouslySetInnerHTML={{__html: `<label>Notes:</label>${notes ? notes : '--'}`}}></td>
        </tr> 
        <tr>
        {all_specs &&  (
          <td>
            <label>MRQ Specs:</label>
            {all_specs.join(', ')}
          </td>
        )}
        {all_files && (
          <>
            <td>
              <label>MRQ File Types:</label>
              {all_files.filter((file: Partial<MaterialRequestFile>) => file.file_name === null).map((file: Partial<MaterialRequestFile>) => file.file_type_display).join(', ')}
            </td>
            <td>
              <label>MRQ Files:</label>
              {all_files.filter((file: Partial<MaterialRequestFile>) => file.file_name !== null).map((file: Partial<MaterialRequestFile>) => (
                <Button
                  onClick={() => getFile(file.id, file.file_name, 'material_request_file')}
                  style={{marginRight: '8px', marginBottom: '8px'}}
                  key={`mrq-file-${file.id?.toString()}`}
                >
                  <DownloadOutlined/>
                  {file.file_type_display}
                </Button>
              ))}
            </td>
          </>
        )}
        </tr>  
        <tr>
          <td>
            <label>Requested Quotation Expiration Date:</label>
            {req_exp ? moment(req_exp).format('MM/DD/YY H:mm a') : '--'}
          </td>
        </tr>              
      </tbody>
    </table>
  );
};
  
export default RFQDetailsTable;
