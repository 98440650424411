import { useState } from 'react';

interface authToken {
  token?: string,
}

export default function useToken() {
  const getToken = () => {
    const tokenString: (string| null) = localStorage.getItem('vsmtoken');
    if (tokenString) {
      const userToken = JSON.parse(tokenString);
      return userToken?.token
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken: authToken) => {
    localStorage.setItem('vsmtoken', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  const clearToken = () => {
    localStorage.removeItem('vsmtoken');
    setToken(null);
  }

  return {
    clearToken: clearToken,
    setToken: saveToken,
    token
  }
}
