import type { ColumnsType } from 'antd/es/table';
import { NavLink } from 'react-router-dom';
import Quotation from '../../Interfaces/Quotation';
import { timelineClass } from '../TimelineHelper';
import moment from 'moment';
import numeral from 'numeral';
import QuotationPublishDate from './QuotationPublishDate';

export const QuotationsColumns: ColumnsType<Partial<Quotation>> = [
    {
      title: 'ID',
      dataIndex: 'quotation_header',
      render: ((text, {version, display_doc_number}) => {
        return (<NavLink to={`/quotation/${text}/?version=${version}`}>{display_doc_number}</NavLink>)
      }),
      width: 175,
      sorter: (a, b) => {
        if (a.display_doc_number && b.display_doc_number) {
          return a.display_doc_number.localeCompare(b.display_doc_number);
        }
        return 0;
      }
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier_display',
      sorter: (a, b) => {
        if (a.supplier_display && b.supplier_display) {
          return a.supplier_display.localeCompare(b.supplier_display);
        }
        return 0;
      },      
    },
    {
      title: 'Status',
      dataIndex: 'status_display',
      width: 150,
      sorter: (a, b) => {
        if (a.status_display && b.status_display) {
          return a.status_display.localeCompare(b.status_display);
        }
        return 0;
      },
      render: (text) => (<span className={`request-status ${timelineClass(text)}`} style={{top: '-1px'}}>{text}</span>)
    }, 
    {
      title: "Published",
      dataIndex: "submitted_on",
      render: (text, record) => (
        <QuotationPublishDate  quotation={record}/>
      ),
      width: 150,
      sorter: (a, b) => {
        const aSub = a.submitted_on ? moment(a.submitted_on).format("X") : moment().format('X');
        const bSub = b.submitted_on ? moment(b.submitted_on).format("X") : moment().format('X');
        return Number(aSub) - Number(bSub);
      },
    },
    {
      title: 'Expriation',
      dataIndex: 'expiration_date',
      render: (text) => (moment(text, 'YYYY=MM-DD').format('MM/DD/YYYY')),
      width: 150,
      sorter: (a, b) => {
        return Number(moment(a.expiration_date, 'YYYY-MM-DD').format('X')) - Number(moment(b.expiration_date, 'YYYY-MM-DD').format('X'));
      },
    }, 
    {
      title: 'Delivery',
      dataIndex: 'estimated_delivery_date',
      render: (text) => (moment(text, 'YYYY=MM-DD').format('MM/DD/YYYY')),
      width: 150,
      sorter: (a, b) => {
        return Number(moment(a.estimated_delivery_date, 'YYYY-MM-DD').format('X')) - Number(moment(b.estimated_delivery_date, 'YYYY-MM-DD').format('X'));
      },
    },
    {
      title: 'Gross',
      dataIndex: 'gross_amount',
      render: (text, record) => {
        if (text === null) return ('--');
        if (!record.revised_tender_sum) return (numeral(text).format('$0,000.00'));
        return (
          <>
           <s>{numeral(record.gross_amount).format('$0,000.00')}</s><br />
           {numeral(record.revised_tender_sum).format('$0,000.00')}
          </>
        )
      },
      width: 150,
      sorter: (a, b) => Number(a.nominal_gross_amount) - Number(b.nominal_gross_amount),
    },
    {
      title: 'Lump Discount',
      dataIndex: 'lump_sum_discount',
      render: (text) => {
        if (text === null) return ('--');
        return (numeral(text).format('$0,000.00'));
      },
      width: 150,
      sorter: (a, b) => Number(a.lump_sum_discount) - Number(b.lump_sum_discount),
    },
    {
      title: 'Net',
      dataIndex: 'net_amount',
      render: (text) => {
        if (text === null) return ('--');
        return (numeral(text).format('$0,000.00'));
      },
      width: 150,
      sorter: (a, b) => Number(a.net_amount) - Number(b.net_amount),
    },
    {
      title: 'Term',
      dataIndex: 'payment_term',
      sorter: (a, b) => {
        if (a.payment_term && b.payment_term) {
          return a.payment_term.localeCompare(b.payment_term);
        }
        return 0;
      }      
    }
  ];
