import { useEffect, useRef, } from 'react';
import type { FormInstance } from 'antd/es/form';
import Quotation from '../Interfaces/Quotation';
import QuotationItem from '../Interfaces/QuotationItem';

export const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: boolean }) => {
  const prevVisibleRef = useRef<boolean>();
  useEffect(() => {
    prevVisibleRef.current = visible;
  }, [visible]);
  const prevVisible = prevVisibleRef.current;

  useEffect(() => {
    if (!visible && prevVisible) {
      form.resetFields();
    }
  }, [form, prevVisible, visible]);
};

export const createCloneQuotation = (item: Partial<Quotation>): Partial<Quotation> => {
  const {
    created_at,
    created_by,
    created_by_display,
    contact_display,
    display,
    display_doc_number,
    editable_fields,
    gross_amount,
    id,
    revised_tender_sum,
    net_amount,
    quotation_header,
    quotation_header_display,
    quotation_status,
    quotation_status_display,
    rfq_display,
    status,
    status_display,
    status_typename,
    updated_at,
    updated_by,
    updated_by_display,
    version,
    ...clone
  } = item;
  return clone;
}

export const createCloneQuotationItem = (item: Partial<QuotationItem>): Partial<QuotationItem> => {
  const {
    created_at,
    created_by,
    created_by_display,
    display,
    editable_fields,
    id,
    status_display,
    status_typename,
    updated_at,
    updated_by,
    updated_by_display,
    ...clone
  } = item;
  return clone;
}
