import React, {KeyboardEvent, useEffect, useReducer, useState} from 'react';
import { Button, Radio, RadioChangeEvent,  Spin, Tooltip} from 'antd';
import { NavLink } from 'react-router-dom';

import { reducer, pageState } from '../Common/ListPageConsts';
import { timelineClass } from '../Common/TimelineHelper';
import Quotation from '../Interfaces/Quotation';
import Status from '../Interfaces/Status';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';

const QuoteList = () => {

  /** states for display objects: count, displayStart, displayEnd, searchTerm, Quotation objects list and nextPage */
  let [count, setCount] = useState<number>(0);
  let [displayStart, setDisplayStart] = useState<number>(0);
  let [displayEnd, setDisplayEnd] = useState<number>(0);
  let [searchTerm, setSearchTerm] = useState<string>('');
  let [list, setList] = useState<Partial<Quotation>[]>([]);
  let [nextPage, setNextPage] = useState<boolean>(false);
  let [initialLoad, setInitialLoad] = useState<boolean>(true);
  /** state for stat us filter values */
  let [quotationStatuses, setQuotationStatuses] = useState<Partial<Status>[]>([])  

  /** Reducer to manage state of list query parameters */
  let [state, dispatch] = useReducer(reducer, pageState);

  useEffect(() => {
    axios(`/main/status/?status_type__name=Quote Status`)
    .then(function (response) {
      setQuotationStatuses(response.data.results);
    })
    .catch(function (error) {
      console.log(error)
    });
  }, []);  
 
  /** initial and query param change effect to get list of quotation objects */
  useEffect(() => {
    let queryParams = `&search=${state.term}`;
    if (state.field !== '') {
      queryParams += `&ordering=${state.sort === 'desc' ? '-' : ''}${state.field}`;
    }
    if (state.status !== '' && state.status.indexOf(',') === -1) {
      queryParams += `&status__id=${state.status}`;
    } else if (state.status !== '' && state.status.indexOf(',') > -1) {
      queryParams += `&status_ids=${state.status}`;
    }    
    if (state.page > 1) queryParams += `&offset=${(state.page - 1) * 10}`;
    /** limit the size of the payload by only getting the fields we need for the display */
    const fields = `?fields=id,display_doc_number,expiration_date,estimated_delivery_date,gross_amount,nominal_gross_amount,lump_sum_discount,net_amount,quotation_header,payment_term,version,status_display`;
    axios(`/base/quotation/${fields}${queryParams}`)
    .then(function (response) {
      let start_result = 1;
      let end_result = response.data.page_size;
      if (response.data.count === 0) start_result = 0;
      if (response.data.page !== 1) {
        /** we need to add one to the start_results */
        start_result = ((response.data.page - 1) * response.data.page_size) + 1;
        end_result = response.data.page * response.data.page_size;
        if (end_result > response.data.count) end_result = response.data.count;
      }
      if (response.data.count < end_result) end_result = response.data.results.length;
      setInitialLoad(false);
      setDisplayStart(start_result);
      setDisplayEnd(end_result);    
      setCount(response.data.count);
      setList(response.data.results);
      setNextPage(response.data.next !== null);
    })
    .catch(function (error) {
      console.log(error)
    });
  }, [state])    

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      dispatch({
        type: 'searchTerm',
        term: searchTerm,
      });
    }
  };
  
  const [filterValue, setFilterValue] = useState<string>('');
  const filterChange = (e: RadioChangeEvent) => {
    const selectedStatus = e.target.value;
    setFilterValue(selectedStatus);
    const matchingStatus = quotationStatuses.find((stat: Partial<Status>) => stat.name === selectedStatus);
    if (matchingStatus) {
      dispatch({
        type: 'status',
        value: matchingStatus.id?.toString()
      });
    }
  };

  return (
  <div className="card quote-list">
    <h3>Quotations</h3>
    <div className="card-body">
      {!initialLoad && (<>
        <Button className="list-reset" onClick={() => {
            setFilterValue('');
            setSearchTerm('');
            dispatch({ type: 'reset' });
          }}>Reset</Button>
        <div id="search-box">
          <label htmlFor="search-field"><i className="material-icons">search</i></label>
          <input 
              type="text"
              id="search-field"
              autoComplete="off"
              placeholder="Search Quotations"
              value={searchTerm}
              onChange={(e) => {setSearchTerm(e.target.value)}}
              onKeyDown={handleKeyDown}
            />
        </div>
      </>)}      
    {!initialLoad && (<div className="status-filters">
      <Radio.Group onChange={filterChange} buttonStyle="solid" value={filterValue}>
        <Tooltip placement="top" title="In Progress">
          <Radio.Button value="In Progress">
            <i className="material-icons-outlined">settings</i>
          </Radio.Button>
        </Tooltip>
        <Tooltip placement="top" title="In Review">
          <Radio.Button value="In Review">
            <i className="material-icons-outlined">pending_actions</i>
          </Radio.Button>
        </Tooltip>
        <Tooltip placement="top" title="Selected">
          <Radio.Button value="Closed Selected">
            <i className="material-icons-outlined">thumb_up</i>
          </Radio.Button>
        </Tooltip> 
        <Tooltip placement="top" title="Rejected">
          <Radio.Button value="Closed Rejected">
            <i className="material-icons-outlined">thumb_down</i>
          </Radio.Button>
        </Tooltip>  
        <Tooltip placement="top" title="Canceled">
          <Radio.Button value="Cancelled">
            <i className="material-icons">block</i>
          </Radio.Button>
        </Tooltip>      
      </Radio.Group>
      </div>)}
      {initialLoad ? (<div className="loading"><Spin></Spin><span className="msg">loading...</span></div>) : list.length === 0 ? (<><span style={{clear: 'both', display: 'block', height: '16px'}}></span><div className="no-results">There are no quotations matching the specified parameters.</div></>) : (<div>
      <div className="table-display-message">Displaying {displayStart} - {displayEnd} of {count} Quotations</div>
      <table className="list">
        <thead>
          <tr>
            <th style={{width: '200px'}}>
              <button
                className={`sorter ${state.field === 'display_doc_number' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'display_doc_number' }); }}>
                ID
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>              
            </th>
            <th style={{width: '150px'}}>
              <button
                className={`sorter ${state.field === 'status__name' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'status__name' }); }}>
                Status
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>              
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'expiration_date' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'expiration_date' }); }}>
                Expiration
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>              
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'estimated_delivery_date' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'estimated_delivery_date' }); }}>
                Expected Delivery
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'nominal_gross_amount' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'nominal_gross_amount' }); }}>
                Gross
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>              
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'lump_sum_discount' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'lump_sum_discount' }); }}>
                Lump Discount
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>   
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'net_amount' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'net_amount' }); }}>
                Net
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>              
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'payment_term' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'payment_term' }); }}>
                Term
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>               
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map(
            ({id, display_doc_number, expiration_date, estimated_delivery_date, gross_amount, nominal_gross_amount, lump_sum_discount, net_amount, quotation_header, payment_term, status_display, version}) => (
              <tr key={id?.toString()}>
                <td><NavLink to={`/quotation/${quotation_header}/?version=${version}`}>{display_doc_number}</NavLink></td>
                <td style={{width: '100px'}}>
                  <span className={`request-status ${timelineClass(status_display)}`}>{status_display}</span>
                </td>
                <td>{moment(expiration_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
                <td>{moment(estimated_delivery_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
                <td>{nominal_gross_amount !== null ? numeral(nominal_gross_amount).format('$0,000.00') : '--'}</td>
                <td>{lump_sum_discount !== null ? numeral(lump_sum_discount).format('$0,000.00') : '--'}</td>
                <td>{net_amount !== null ? numeral(net_amount).format('$0,000.00') : '--'}</td>
                <td>{payment_term}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
      <div className="pagination">
        <button type="button"
          className={state.page < 2 ? "disabled" : ""}
          disabled={state.page < 2}
          onClick={() => { dispatch({ type: 'previousPage' }); }}>
          <i className="material-icons">chevron_left</i>
        </button>
        <button type="button"
          className={nextPage === false ? "disabled" : ""}
          onClick={() => { dispatch({ type: 'nextPage' }); }}
          disabled={nextPage === false}>
          <i className="material-icons">chevron_right</i>
        </button>
      </div>
      </div>)}
    </div>
  </div>
);
}

export default QuoteList;
