import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, InputNumber, Modal, Tooltip, Switch } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';

import FileObjectProps from '../../Interfaces/FileObjectProps';
import QuotationItemFile from '../../Interfaces/QuotationItemFile';
import QuotationItemSpec from '../../Interfaces/QuotationItemSpec';
import QuotationItem from '../../Interfaces/QuotationItem';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

const { TextArea } = Input;

interface EditQuoteItemModalProps { 
  items: Partial<QuotationItem>[];
  quoteItem: Partial<QuotationItem>,
  setItems: Dispatch<SetStateAction<Partial<QuotationItem>[]>>;
  updateStatus: Function;
}

const EditQuotationItemModal = ({
  items,
  quoteItem,
  setItems,
  updateStatus,
}: EditQuoteItemModalProps) => {

  let [isQuoteItemVisible, setIsQuoteItemVisible] = useState<boolean>(false);
  let [confirmLoading, setConfirmLoading] = useState(false);
  let [rateOnlyItem, setRateOnlyItem] = useState<boolean>(false);
  const [form] = Form.useForm();
  const showQuoteItemModal = (): void => setIsQuoteItemVisible(true);
  const hideQuoteItemModal = (): void => {
    setIsQuoteItemVisible(false);
    setConfirmLoading(false);
  }
  const onOk = (): void => form.submit();

  const onFinish = (values: any): void => {
    setConfirmLoading(true);
    const editedQI: Partial<QuotationItem> = {};
    editedQI.id = quoteItem.id;
    editedQI.rate_only_item = values.rate_only_item;
    editedQI.description = values.description;
    editedQI.quantity = values.quantity;
    editedQI.in_stock_qty = values.in_stock_qty;
    editedQI.amount_per_item = values.amount_per_item;
    editedQI.notes = values.notes ? values.notes : '';
    editedQI.line_discount = values.line_discount;
    editedQI.lead_time_days = values.lead_time_days;
    editedQI.estimated_delivery_date = values.estimated_delivery.format('YYYY-MM-DD');
    axios({method: 'PATCH', url: `/base/quotation_item/${quoteItem.id}/`, data: editedQI})
    .then(function (qiResponse) {
      const {
        rate_only_item,
        amount_per_item,
        description,
        estimated_delivery,
        in_stock_qty,
        lead_time_days,
        line_discount,
        notes,
        quantity,
        ...specValues
      } = values;
      const editedQISpecs: Partial<QuotationItemSpec>[] = [];
      const editedQIFiles: Partial<QuotationItemFile>[] = [];
      for (const [key, value] of Object.entries(specValues)){
        if (key.includes('file_')) {
          if (value)  {
            const fileTypeId = Number(key.replace('file_', ''));
            const inputData = value as Array<Partial<FileObjectProps>>;
            if (inputData[0].originFileObj && inputData[0].type) {
              editedQIFiles.push({
                file_name: inputData[0].name,
                file_object: inputData[0].originFileObj,
                content_type: inputData[0].type,
                generate_upload_url: true,
                id: fileTypeId,
              })
            }
          }
        } else {
          const specId = Number(key);
          const matchingSpec = quoteItem.specs?.find((spec: Partial<QuotationItemSpec>) => spec.material_request_item_spec === specId);
          editedQISpecs.push({
            id: matchingSpec?.id,
            item: qiResponse.data.id,
            material_request_item_spec: specId,
            spec_required: true,
            spec_response: String(value),
            spec_note: '',
          });
        }
      }
      const calls: Promise<AxiosResponse>[] = [
        axios({method: 'POST', url: '/base/quotation_item_spec/bulk_load/', data: editedQISpecs})
      ]
      editedQIFiles.forEach((fileData) => {
        const matchingFile = quoteItem.files?.find((existingFile: Partial<QuotationItemFile>) => existingFile.file_type === fileData.id);
        if (matchingFile) {
          delete fileData.id;
          calls.push(axios({
            method: 'PATCH',
            url: `/base/quotation_item_file/${matchingFile.id}/`,
            data: fileData
          }));
        }
      });
      axios.all(calls).then(axios.spread((...responses) => {
        const uploads: Promise<AxiosResponse>[] = [];
        responses.forEach((res, index) => {
          if (index > 0) {
            const fileData = editedQIFiles[index - 1];
            uploads.push(
              axios.put(res.data.signed_upload_url, fileData.file_object, {
                headers: {
                  'Content-Type': fileData.content_type || ''
                }
              })
            );
          }
        });
        axios.all(uploads).then(axios.spread((...uploadResponses) => {
          axios.all([
            axios(`/base/quotation_item_spec/?limit=10000&item=${qiResponse.data.id}&ordering=material_request_item_spec__seq`),
          ]).then(axios.spread((...lookupResponses) => {
            qiResponse.data.key = qiResponse.data.id;
            qiResponse.data.specs = lookupResponses[0].data.results;
            const nonEditedItems = items.filter((item: Partial<QuotationItem>) => item.id !== qiResponse.data.id);
            setItems([qiResponse.data, ...nonEditedItems]);
            updateStatus();
            hideQuoteItemModal();
          })).catch(errors => {
            console.log(errors)
          });
        })).catch(errors => {
          console.log(errors)
        });
      })).catch(errors => {
        console.log(errors)
      });
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  useEffect(() => {
    form.setFieldsValue({
      description: quoteItem.description,
      rate_only_item: quoteItem.rate_only_item,
      quantity: quoteItem.quantity,
      in_stock_qty: quoteItem.in_stock_qty,
      amount_per_item: quoteItem.amount_per_item,
      notes: quoteItem.notes,
      line_discount: quoteItem.line_discount,
      lead_time_days: quoteItem.lead_time_days,
      estimated_delivery: moment(quoteItem.estimated_delivery_date, 'YYYY-MM-DD'),
    });
    quoteItem.specs?.forEach((spec: Partial<QuotationItemSpec>) => {
      const obj: {[k: string]: any} = {};
      obj[String(spec.material_request_item_spec)] = spec.spec_response || ''; 
      form.setFieldsValue(obj);
    })
    setRateOnlyItem(quoteItem.rate_only_item ?? false);
  }, [quoteItem, form]);
  
  return (
    <>
      <Tooltip placement="top" title="Edit">
        <Button onClick={showQuoteItemModal} size="small">
          <EditOutlined />
        </Button>
      </Tooltip>
      <Modal 
        confirmLoading={confirmLoading}
        title="Edit Quotation Item"
        visible={isQuoteItemVisible}
        onOk={onOk}
        okText="Submit"
        onCancel={hideQuoteItemModal}
        width="98%"
        keyboard={false}
        maskClosable={false}
        style={{ top: 20 }}>
        <Form 
          layout="vertical"
          name="quotationItemForm"
          form={form}
          onFinish={onFinish}>
          <div className="split-form">
            <div className="split-form-section">
              <Form.Item
                  label="Item Description"
                  name="description" 
                  rules={[{ required: true, message: 'Please supply an item desciption.' }]}>
                <TextArea
                  rows={3} 
                  placeholder="Please provide an item description"/>
              </Form.Item> 
              <div className="fifty-split">
                <div>
                  <Form.Item 
                    label="Rate Only"
                    name="rate_only_item"
                    valuePropName="checked">
                    <Switch
                      onChange={() => {
                        setRateOnlyItem(!rateOnlyItem);
                      }}/>
                  </Form.Item> 
                </div>
                <div>
                  <Form.Item 
                    label="Quantity"
                    name="quantity"
                    rules={[{ required: (rateOnlyItem ? false : true), message: 'Please enter a quantity.' }]}>
                    <InputNumber
                      style={{ width: '100%'}}
                      step="100"
                      placeholder="Enter quantity"/>
                  </Form.Item> 
                </div>
                <div>
                  <Form.Item 
                    label="In stock quantity"
                    name="in_stock_qty">
                    <InputNumber
                      style={{ width: '100%'}}
                      step="100"
                      placeholder="Enter quantity"/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Amount per item"
                    name="amount_per_item"
                    rules={[{ required: true, message: 'Please supply an amount per item.' }]}>
                    <InputNumber
                      step="100"
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%'}}
                      placeholder="Enter amount per item"/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Line discount"
                    name="line_discount"
                    rules={[{ required: true, message: 'Please supply a line dicount.' }]}>
                    <InputNumber
                      step="100"
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value!.replace(/\$\s?|(,*)/g, '')}
                      style={{ width: '100%'}}
                      placeholder="Enter discount"/>
                  </Form.Item> 
                </div>
                <div>
                  <Form.Item 
                    label="Lead time in days"
                    name="lead_time_days"
                    rules={[{ required: true, message: 'Please enter a lead time.' }]}>
                    <InputNumber
                      style={{ width: '100%'}}
                      step="1"
                      placeholder="Enter lead time in days"/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Estimated delivery date"
                    name="estimated_delivery"
                    rules={[{ required: true, message: 'Please supply an estimated delivery date.' }]}>
                    <DatePicker style={{width: '100%'}}/>
                  </Form.Item> 
                </div>
              </div>
              <Form.Item label="Notes" name="notes">
                <TextArea
                  rows={3} 
                  placeholder="Please provide any other notes"/>
              </Form.Item>         
            </div>
            <div className="split-form-section right">
                <span className="quoteitem-spec-file-header">Specs:</span>
                <div className="fifty-split">
                  { quoteItem.specs?.map((spec: Partial<QuotationItemSpec>) => (
                    <div key={spec.id?.toString()}>
                      <Form.Item label={spec.material_request_item_spec_spec} name={spec.material_request_item_spec}>
                        <Input placeholder={spec.material_request_item_spec_spec}/>
                      </Form.Item>
                    </div>
                  ))}
                </div>
            </div>
          </div>
        </Form>
      </Modal>   
    </>
  );
}

export default EditQuotationItemModal;
