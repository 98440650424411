import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import RFQ from '../../Interfaces/RFQ';
import { timelineClass } from '../TimelineHelper';
import 'antd/dist/antd.css';

interface RowProps {  
  rfq: Partial<RFQ>,
}

const RFQRow = ({ rfq }: RowProps) => {
  return (
    <tr>
      <td className="doc-id">
        {rfq.live_and_targets_me && (<Tooltip placement="top" title="Targeted RFQ">
          <i className="material-icons targeted-icon">ads_click</i>
        </Tooltip>)}
        <NavLink to={`/rfq/${rfq.rfq_header}/?version=${rfq.version}`}>{rfq.display_doc_number}</NavLink>
      </td>
      <td style={{width: '100px'}}>
        <span className={`request-status ${timelineClass(rfq.status_display)}`}>{rfq.status_display}</span>
      </td>
      <td dangerouslySetInnerHTML={{__html: rfq.rfq_title || '--'}}></td>
      <td>{rfq.rfq_category_display}</td>
      <td>{rfq.contact_display}</td>
    </tr>
  );
};

export default RFQRow;
