import React, {KeyboardEvent, useEffect, useReducer, useState} from 'react';
import { Button, Spin} from 'antd';

import RFQ from '../Interfaces/RFQ';
import axios from 'axios';
import {reducer, pageState} from '../Common/ListPageConsts';
import RFQRow from '../Common/RFQ/RFQRow';

const RFQList = () => {

  let [initialLoad, setInitialLoad] = useState<boolean>(true);
  /** states for display objects: count, displayStart, displayENd, searchTerm, RFQ objects list and nextPage */
  let [count, setCount] = useState<number>(0);
  let [displayStart, setDisplayStart] = useState<number>(0);
  let [displayEnd, setDisplayEnd] = useState<number>(0);
  let [searchTerm, setSearchTerm] = useState<string>('');
  let [list, setList] = useState<Partial<RFQ>[]>([]);
  let [nextPage, setNextPage] = useState<boolean>(false);

  /** Reducer to manage state of list query parameters */
  let [state, dispatch] = useReducer(reducer, pageState);

  /** initial and query param change effect to get list of RFQ objects */
  useEffect(() => {
    let queryParams = `&search=${state.term}`;
    if (state.field !== '') {
      queryParams += `&ordering=${state.sort === 'desc' ? '-' : ''}${state.field}`;
    }
    if (state.status !== '' && state.status.indexOf(',') === -1) {
      queryParams += `&status__id=${state.status}`;
    } else if (state.status !== '' && state.status.indexOf(',') > -1) {
      queryParams += `&status_ids=${state.status}`;
    }    
    if (state.page > 1) queryParams += `&offset=${(state.page - 1) * 10}`;
    /** limit the size of the payload by only getting the fields we need for the display */
    const fields = `?fields=id,contact,display_doc_number,rfq_category,status,rfq_header,material_request,rfq_title,version,mrq_header_id,mrq_version,mrq_display_doc_number,live_and_public,live_and_targets_me`;
    axios(`/base/rfq/${fields}${queryParams}`)
    .then(function (response) {
      let start_result = 1;
      let end_result = response.data.page_size;
      if (response.data.count === 0) start_result = 0;
      if (response.data.page !== 1) {
        /** we need to add one to the start_results */
        start_result = ((response.data.page - 1) * response.data.page_size) + 1;
        end_result = response.data.page * response.data.page_size;
        if (end_result > response.data.count) end_result = response.data.count;
      }
      if (response.data.count < end_result) end_result = response.data.results.length;
      setInitialLoad(false);
      setDisplayStart(start_result);
      setDisplayEnd(end_result);    
      setCount(response.data.count);
      setList(response.data.results);
      setNextPage(response.data.next !== null);
    })
    .catch(function (error) {
      console.log(error)
    });
  }, [state])  
  
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      dispatch({
        type: 'searchTerm',
        term: searchTerm,
      });
    }
  };
  
  return (
  <div className="card rfq-list">
    <h3>RFQs</h3>
    <div className="card-body">
      {!initialLoad && (<>
        <Button className="list-reset" onClick={() => {
            setSearchTerm('');
            dispatch({ type: 'reset' });
          }}>Reset</Button>
        <div id="search-box">
          <label htmlFor="search-field"><i className="material-icons">search</i></label>
          <input 
              type="text"
              id="search-field"
              autoComplete="off"
              placeholder="Search RFQs"
              value={searchTerm}
              onChange={(e) => {setSearchTerm(e.target.value)}}
              onKeyDown={handleKeyDown}
            />
        </div>
      </>)}
      {initialLoad ? (<div className="loading"><Spin></Spin><span className="msg">loading...</span></div>) : list.length === 0 ? (<><span style={{clear: 'both', display: 'block', height: '16px'}}></span><div className="no-results">There are no RFQs matching the specified parameters.</div></>) : (<div>   
      <div className="table-display-message">Displaying {displayStart} - {displayEnd} of {count} RFQs</div>
      <table className="list">
        <thead>
          <tr>
            <th style={{width: '200px'}}>
              <button
                className={`sorter ${state.field === 'display_doc_number' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'display_doc_number' }); }}>
                ID
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>
            </th>
            <th style={{width: '130px'}}>
              <button
                className={`sorter ${state.field === 'status__name' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'status__name' }); }}>
                Status
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'rfq_title' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'rfq_title' }); }}>
                Title
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'rfq_category__encoded_category_name' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'rfq_category__encoded_category_name' }); }}>
                Category
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>              
            </th>
            <th>
              <button 
                className={`sorter ${state.field === 'contact__first_name' ? 'active' : ''} ${state.sort}`}
                onClick={() => { dispatch({ type: 'sortByField', fieldName: 'contact__first_name' }); }}>
                Point of Contact
                <div className="indicators">
                  <i className="material-icons">arrow_drop_up</i>
                  <i className="material-icons">arrow_drop_down</i>
                </div>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
        {list.map((rfq: Partial<RFQ>) => (<RFQRow rfq={rfq} key={rfq.id?.toString()}/>))}
        </tbody>
      </table>
      <div className="pagination">
        <button type="button"
          className={state.page < 2 ? "disabled" : ""}
          disabled={state.page < 2}
          onClick={() => { dispatch({ type: 'previousPage' }); }}>
          <i className="material-icons">chevron_left</i>
        </button>
        <button type="button"
          className={nextPage === false ? "disabled" : ""}
          onClick={() => { dispatch({ type: 'nextPage' }); }}
          disabled={nextPage === false}>
          <i className="material-icons">chevron_right</i>
        </button>
      </div></div>)}
    </div>
  </div>
);
};

export default RFQList;
