import React, { useEffect, useState }  from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './Common/Header';
import ScrollToTop from './Common/ScrollToTop';
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import QuoteDetails from './Pages/QuoteDetails';
import QuotationEditForm from './Pages/QuotationEditForm';
import QuoteList from './Pages/QuoteList';
import RFQDetails from './Pages/RFQDetails';
import RFQList from './Pages/RFQList';
import BestOfferList from './Pages/BestOfferList';
import TenderQueryList from './Pages/TenderQueryList';
import CurrentUser from './Interfaces/CurrentUser';
import useToken from './useToken';
import axios from 'axios';

const env = (window as { [key: string]: any })["_env_"];
axios.defaults.baseURL = env.BASE_URL;
// axios.defaults.headers.common['Authorization'] = 'Token 953615a146082bb8666e384f6ca2abc569678f9a2106bda1e409f9b4805a8b21b86cd84e6547afb97470d233acbd1487a0b6632faaf4d9956919d2f1873d9881';
function App() {

  const { token, setToken, clearToken } = useToken();
  const [currentUser, setCurrentUser] = useState<Partial<CurrentUser>>({});

  useEffect(() => {
    if (token) {
      axios('/base/my_info')
      .then(function (response) {
        setCurrentUser(response.data);
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  }, [token])

  axios.interceptors.response.use(
    res => res,
    err => {
      const statusCode = err.response.status;
      if (statusCode === 401) {
       clearToken();
      }
      throw err;
    }
  );  
  axios.defaults.headers.common['Authorization'] = `Token ${token}`;

  return (<>
    <Router>
      {!token && (<Login setToken={setToken} />)}
      {token && (<>
        <ScrollToTop />
        <Header user={currentUser} clearToken={clearToken}/>
        <Routes>
          <Route path="/" element={<Dashboard></Dashboard>}/>
          <Route path="/quotations" element={<QuoteList></QuoteList>}/>
          <Route path="/rfqs" element={<RFQList></RFQList>}/>
          <Route path="/quotation/:quotationId" element={<QuoteDetails></QuoteDetails>}/>
          <Route path="/edit_quotation/:quotationId/" element={<QuotationEditForm user={currentUser}></QuotationEditForm>}/>
          <Route path="/rfq/:rfqId" element={<RFQDetails user={currentUser}></RFQDetails>}/>
          <Route path="/tender_queries" element={<TenderQueryList></TenderQueryList>}/>
          <Route path="/best_offers" element={<BestOfferList></BestOfferList>}/>
          <Route path="*" element={<div>404 Not Found</div>}/>
        </Routes>
      </>)}
    </Router>
  </>);
}

export default App;
