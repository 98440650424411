import React from 'react';
import { NavLink } from 'react-router-dom';

import './dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <div className="card">
        <h4 style={{fontWeight: 'bold', paddingBottom: '8px', textAlign: 'center', width: '445px'}}>Suppliers</h4>
        <NavLink to="/quotations" className="icon-block">
          <i className="material-icons-outlined">paid</i>
          <span>Quotations</span>
        </NavLink>  
        <div className="pointer">
          <span className="line"></span>
          <span className="triangle-right"></span>
        </div> 
        <NavLink to="/rfqs" className="icon-block">
          <i className="material-icons">connect_without_contact</i>
          <span>RFQs</span>
        </NavLink>     
      </div>
    </div>
  );
};

export default Dashboard;
