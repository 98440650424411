/**
 * @description returns correct css class name for time line status names
 * @param statusName status name string
 * @returns cssClass string
 */
export const timelineClass = (statusName: string|undefined): string => {
  const statusMap = new Map([
    ['In Progress', ''],
    ['In Review', 'orange'],
    ['Cancelled', 'red'],
    ['Closed', 'black'],
    ['Published', 'blue'],
    ['Approved', 'green'],
    ['Rejected', 'red'],
    ['Submitted', 'purple'],
    ['Closed Rejected', 'red'],
    ['Closed Selected', 'green'],
    ['Old Version', 'black'],
  ]);
  const cssClass = statusName ? statusMap.get(statusName) : '';
  return cssClass || '';
}
