import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './tabs.css';

const Tabs = () => {
  const location = useLocation();
  return (
    <nav className="tab-bar">
      <NavLink to="/">Dashboard</NavLink>   
      <NavLink to="/rfqs" className={`${location.pathname.includes("rfq/") ? 'details' : ''}`}>RFQs</NavLink>
      <NavLink to="/quotations" className={`${location.pathname.includes("quotation/") ? 'details' : ''}`}>Quotations</NavLink>
      <NavLink to="/tender_queries" className={`${location.pathname.includes("tender_queries/") ? 'details' : ''}`}>Tender Queries</NavLink>
      <NavLink to="/best_offers" className={`${location.pathname.includes("best_offers/") ? 'details' : ''}`}>Best Offers</NavLink>
    </nav>
  );
}

export default Tabs;
