import ActionObject from '../Interfaces/ActionObject';
import APIList from '../Interfaces/APIList';

/**
 * @description reducer to handle various events that change the list query parameters
 * @param state APIList object
 * @param action ActionObject
 * @returns state
 */
 export const reducer = (state: APIList, action: ActionObject): APIList => {
    if (!action) return state;
    switch (action.type) {
      case 'nextPage':
        return { ...state, page: state.page + 1 };
      case 'previousPage':
        return { ...state, page: state.page - 1 };  
      case 'searchTerm':
        const term = action.term || '';
        return { ...state, term, page: 1};  
      case 'sortByField':
        if (action.fieldName) {
          let sort = state.field === action.fieldName && state.sort === 'asc' ? 'desc' : 'asc' ;
          return { ...state, field: action.fieldName, sort: sort }; 
        }
        return state;
      case 'status':
        if (action.value) {
          return { ...state, status: action.value, page: 1}; 
        }
        return state;
      case 'reset':
        return pageState;
      default:
        return state;
    }
};

  /** initial query state */
export const pageState: APIList = {
  field: '',
  page: 1,
  sort: 'asc',
  status: '',
  term: '',
}

export const activeStatusNames: string[] = ['In Progress', 'Approved', 'In Review', 'Rejected', 'Published'];
