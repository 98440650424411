import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

import moment from 'moment';
import Quotation from '../../Interfaces/Quotation';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface PublishProps {
  quotation: Partial<Quotation>;
  alignment?:  TooltipPlacement | undefined;
}

const QuotationPublishDate = ({ alignment = "top", quotation }: PublishProps) => {

  const [displayFlag, setDisplayFlag] = useState<boolean>(false);
  
  useEffect(() => {
    if (quotation.submitted_on && quotation.rfq_publication_original_end_date) {
      const submissionMoment: moment.Moment = moment(quotation.submitted_on);
      const pubEndMoment: moment.Moment = moment(quotation.rfq_publication_original_end_date);
      if (submissionMoment > pubEndMoment) {
        setDisplayFlag(true);
      }
    }
  }, [quotation]);


  return(
    <div>
      {!quotation.submitted_on && ('--')}
      {displayFlag && (
        <Tooltip placement={alignment} title="Quotation was published after RFQ original publication window ended.">
          <ClockCircleOutlined style={{ color: '#cf1322', marginRight: '6px'}}/>
        </Tooltip>
      )}
      {quotation.submitted_on && (moment(quotation.submitted_on).format('MM/DD/YYYY'))}
    </div>
  );
};

export default QuotationPublishDate; 
