import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import { Button, Dropdown, Menu, Modal, Popconfirm, Spin, Table, Tooltip, Space, InputNumber } from 'antd';
import { DeleteOutlined, DownloadOutlined, DownOutlined, ExclamationCircleOutlined, EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { NavLink, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { saveAs } from "file-saver";

import AddQuotationItemModal from '../Common/Quotation/AddQuotationItemModal';
import EditQuotationItemModal from '../Common/Quotation/EditQuotationItemModal';
import EditQuotationQuestionReplyModal from '../Common/Quotation/EditQuotationQuestionReplyModal';
import RFQDetailsTable from '../Common/RFQ/RFQDetailsTable';
import { createCloneQuotation } from '../Common/FormHelpers';
import { timelineClass } from '../Common/TimelineHelper';
import EscapeText from '../Common/EscapeText';
import Quotation from '../Interfaces/Quotation';
import QuotationFile from '../Interfaces/QuotationFile';
import QuotationItem from '../Interfaces/QuotationItem';
import QuotationItemSpec from '../Interfaces/QuotationItemSpec';
import QuotationItemFile from '../Interfaces/QuotationItemFile';
import QuotationQuestionReply from '../Interfaces/QuotationQuestionReply';
import RFQ from '../Interfaces/RFQ';
import RFQItem from '../Interfaces/RFQItem';
import RFQPublication from '../Interfaces/RFQPublication';
import Status from '../Interfaces/Status';
import XrefQuotationStatus from '../Interfaces/XrefQuotationStatus';
import { TenderQuery } from '../Interfaces/TenderQuery';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';

import './quote_details.css';
import QuotationPublishDate from '../Common/Quotation/QuotationPublishDate';

const env = (window as { [key: string]: any })["_env_"];

const { confirm } = Modal;

interface actionMenuProps {
  id: number|undefined,
  changeStatus: Function,
  confirmCloneQuotation: Function,
  confirmCancelQuotation: Function,
  qItems: Partial<QuotationItem>[],
  newVersion: Function,
  status: string|undefined,
  allowSubmit: boolean,
}

const actionsMenu = ({
  changeStatus,
  confirmCloneQuotation,
  confirmCancelQuotation,
  id,
  qItems,
  newVersion,
  status,
  allowSubmit,
}: actionMenuProps) => {
  const items: ItemType[] = [];
  if (allowSubmit && status === 'In Progress') {
    items.push({
      label: (<NavLink to={`/edit_quotation/${id}/`}>Edit Quotation</NavLink>),
      key: '1',
    },{
      type: 'divider',
    });

    if(qItems.length > 0)  {
      items.push({
        label: (<span onClick={() => { changeStatus('In Review'); }}>Submit</span>),
        key: '2'
      });
    }
  }
  if (allowSubmit && status === 'In Review') {
    items.push({
      label: (<span onClick={() => { newVersion(); }}>New Version</span>),
      key: '5',
    },{
      type: 'divider',
    });
  }
  /** if not cancelled include the option */
  if (allowSubmit && status && !['Cancelled','Closed Selected','Closed Rejected'].includes(status)) {
    items.push({
      label: (<span onClick={() => { confirmCancelQuotation(); }}>Cancel</span>),
      key: '4'
    });
  }
  /** if canceled include uncancel option */
  if (allowSubmit && status === 'Cancelled') {
    items.push(    {
      label: (<span onClick={() => { changeStatus('In Progress'); }}>Uncancel</span>),
      key: '8'
    });
  }
  /** the default last two items in list */
  if (allowSubmit) {
    items.push({
      type: 'divider',
    },{
      label: (<span onClick={() => confirmCloneQuotation()}>Clone Quotation</span>),
      key: '7',
    });
  } else {
    items.push({
      label: (<span>No action is available due to beyond the response due date</span>),
      key: '7',
    });
  }
  return (<Menu items={items}/>);
};

interface versionMenuProps {
  allVersions: Partial<Quotation>[];
}

const versionMenu = ({allVersions}: versionMenuProps) => {

  const items = allVersions.map((version: Partial<Quotation>) => {
    return {
      label: (<NavLink to={`/quotation/${version.quotation_header}/?version=${version.version}`}>Version: {version.version}</NavLink>),
      key: String(version.id)
    }
  });

  return (<Menu items={items}/>);
};

const QuoteDetails = () => {

  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [currentVersion, setCurrentVersion] = useState<Partial<Quotation>>({});
  const [allVersions, setAllVersions] = useState<Partial<Quotation>[]>([]);
  const [RFQ, setRFQ] = useState<Partial<RFQ>>({});
  const [rfqItems, setRFQItems] = useState<Partial<RFQItem>[]>([]);
  const [items, setItems] = useState<Partial<QuotationItem>[]>([]);
  const [publications, setPublications] = useState<Partial<RFQPublication>[]>([]);
  const [quotationStatuses, setQuotationStatuses] = useState<Partial<Status>[]>([]);
  const [timeline, setTimeline] = useState<Partial<XrefQuotationStatus>[]>([]);
  const [activeStatus, setActiveStatus] = useState<Partial<Status>>({});
  const [files, setFiles] = useState<Partial<QuotationFile>[]>([]);
  const [questionReply, setQuestionReply] = useState<Partial<QuotationQuestionReply>[]>([]);
  const [tenderQueries, setTenderQueries] = useState<Partial<TenderQuery>[]>([]);
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [isRevisingLumpSumDiscount, setIsRevisingLumpSumDiscount] = useState<boolean>(false);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const version = searchParams.get("version");
  const urlParams = useParams();
  const navigate = useNavigate();
  const inputLumpSumDiscountRef: MutableRefObject<HTMLInputElement|null> = useRef(null);

  useEffect(() => {
    axios.all([
      axios(`/main/status/?status_type__name=Quote Status`),
    ]).then(axios.spread((...responses) => {
      setQuotationStatuses(responses[0].data.results);
    })).catch(errors => {
      console.log(errors);
    });
  }, []);

  useEffect(() => {
    /** get all versions of quotation for the given quotation_header */
    axios(`/base/quotation/?limit=10000&quotation_header=${urlParams.quotationId}`)
    .then(function (response) {
      setIsRevisingLumpSumDiscount(false);
      /** find the version we want to display and set it as currentVersion in state */
      const displayRequest = response.data.results.find((rfq: Partial<Quotation>) => String(rfq.version) === version);
      setCurrentVersion(displayRequest || {});
      /** set all versions for the quotation_header */
      setAllVersions(response.data.results.sort((a: Partial<Quotation>, b: Partial<Quotation>) => Number(b.version) - Number(a.version)));
      axios.all([
        axios(`/base/rfq/?limit=10000&id=${displayRequest.rfq}`),
        axios(`/base/quotation_item/?limit=10000&quotation=${displayRequest.id}`),
        axios(`/base/rfq_item/?limit=10000&rfq=${displayRequest.rfq}`),
        axios(`/main/status/?status_type__name=Is Active&name=Active`),
        axios(`/base/rfq_publication/?limit=100&ordering=-created_at&status__name=Active&rfq=${displayRequest.rfq}`),
        axios(`/base/xref_quotation_status/?limit=100&quotation=${displayRequest.id}&fields=status,created_at,created_by,id&ordering=-created_at`),
        axios(`/base/quotation_item_spec/?limit=10000&item__quotation=${displayRequest.id}&ordering=material_request_item_spec__seq`),
        axios(`/base/quotation_item_file/?limit=10000&item__quotation=${displayRequest.id}&ordering=file_type__file_type_name`),
        axios(`/base/quotation_file/?limit=10000&quotation=${displayRequest.id}&ordering=file_type__file_type_name`),
        axios(`/base/quotation_question_reply/?quotation=${displayRequest.id}&ordering=id`),
        axios(`/base/tender_query/get_by_quotation_id/?quotation_id=${displayRequest.id}`),
      ]).then(axios.spread((...responses) => {
        setRFQ(responses[0].data.results[0]);
        setItems(responses[1].data.results.map((item: Partial<QuotationItem>) => {
          item.specs = responses[6].data.results.filter((spec: Partial<QuotationItemSpec>) => spec.item === item.id);
          item.files = responses[7].data.results.filter((file: Partial<QuotationItemFile>) => file.quotation_item === item.id);
          return {...item, key: item.id}
        }));
        setRFQItems(responses[2].data.results.map((item: Partial<RFQItem>) => {
          return {...item, key: item.id}
        }));
        setActiveStatus(responses[3].data.results[0]);
        setPublications(responses[4].data.results);
        setTimeline(responses[5].data.results);
        setFiles(responses[8].data.results);
        setQuestionReply(responses[9].data.results);
        setTenderQueries(responses[10].data);

        let withinPublication = false
        if (responses[10].data.length > 0) {
          if (responses[10].data[0].response_deadline && moment().isBefore(responses[10].data[0].response_deadline)) {
            withinPublication = true;
          }
        } else if (responses[4].data.results.length > 0) {
          if (responses[4].data.results[0].publication_end && moment().isBefore(responses[4].data.results[0].publication_end)) {
            withinPublication = true;
          }
        }
        setAllowSubmit(withinPublication);

        setInitialLoad(false);
      })).catch(errors => {
        console.log(errors)
      });
    })
    .catch(function (error) {
      console.log(error)
    });
  }, [urlParams, version]);

  const RFQItemColumns: ColumnsType<Partial<RFQItem>> = [
    {
      title: 'Display',
      dataIndex: 'display',
      render: (text, record) => (<EscapeText text={text}/>),
      sorter: (a, b) => {
        if (a.display && b.display) {
          return a.display.localeCompare(b.display);
        }
        return 0;
      },
      width: 300  
    },    
    {
      title: 'Quantity',
      dataIndex: 'rfq_item_qty',
      render: (text: string) => (numeral(text).format('0,000')),
      width: 150,
      sorter: (a, b) => Number(a.rfq_item_qty) - Number(b.rfq_item_qty),
    },  
    {
      title: 'Unit of Measurment',
      dataIndex: 'rfq_item_unit_of_measurement_display',
      width: 200,
      sorter: (a, b) => {
        if (a.rfq_item_unit_of_measurement_display && b.rfq_item_unit_of_measurement_display) {
          return a.rfq_item_unit_of_measurement_display.localeCompare(b.rfq_item_unit_of_measurement_display);
        }
        return 0;
      }
    },
    {
      title: 'Delivery Date',
      dataIndex: 'rfq_item_delivery_date',
      render: (text: string) => (moment(text, 'YYYY-MM-DD').format('MM/DD/YYYY')),
      width: 150,
      sorter: (a, b) => {
        return Number(moment(a.rfq_item_delivery_date, 'YYYY-MM-DD').format('X')) - Number(moment(b.rfq_item_delivery_date, 'YYYY-MM-DD').format('X'));
      }, 
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      render: (text, record) => (<EscapeText text={text}/>),
      width: 300,
      sorter: (a, b) => {
        if (a.notes && b.notes) {
          return a.notes.localeCompare(b.notes);
        }
        return 0;
      }    
    }
  ];

  const confirmCancelQuotation = () => {
    confirm({
      title: 'Are you sure you want to cancel this Quotation?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      width: '530px',
      keyboard: false,
      maskClosable: false,
      onOk() {
        changeStatus('Cancelled');
      },
      onCancel() {},
    });
  }

  const confirmCloneQuotation = () => {
    confirm({
      title: 'Are you sure you want to clone this Quotation?',
      content: 'This will create a new Quotation with the same settings.',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      width: '50%',
      keyboard: false,
      maskClosable: false,
      onOk() {
        const newQuotation: Partial<Quotation> = createCloneQuotation(currentVersion);
        axios({method: 'POST', url: '/base/quotation_header/', data: {status: activeStatus.id}})
        .then(function (headerResponse) {
          newQuotation.quotation_header = headerResponse.data.id;
          axios({method: 'POST', url: '/base/quotation/', data: newQuotation})
          .then(function (quoteResponse) {
            // clone quote items will go here
            navigate(`/quotation/${headerResponse.data.id}/?version=${quoteResponse.data.version}`);
          })
          .catch(function (error) {
            console.log(error)
          });
        })
        .catch(function (error) {
          console.log(error)
        }); 
      },
      onCancel() {},
    });
  };  
  
  const updateStatus = (): void => {
    axios.all([
      axios(`/base/quotation/${currentVersion.id}/`),
      axios(`/base/xref_quotation_status/?limit=100&quotation=${currentVersion.id}&fields=status,created_at,created_by,id&ordering=-created_at`),
    ]).then(axios.spread((...responses) => {
      setCurrentVersion(responses[0].data);
      setTimeline(responses[1].data.results);
    })).catch(errors => {
      console.log(errors)
    });
  }

  const changeStatus = (statusName: string): void => {
    const matchingStatus = quotationStatuses.find((stat: Partial<Status>) => stat.name === statusName);
    if (matchingStatus) {
      axios({
        method: 'POST',
        url: `/base/xref_quotation_status/`,
        data: {
          status: matchingStatus.id,
          quotation: currentVersion.id,
        }
      })
      .then(function (response) {
        updateStatus();
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  }

  const QuotationItemColumns: ColumnsType<Partial<QuotationItem>> = [
    {
      title: 'Item Description',
      dataIndex: 'description',
      fixed: 'left',
      width: 350,
      sorter: (a, b) => {
        if (a.description && b.description) {
          return a.description.localeCompare(b.description);
        }
        return 0;
      }
    },
    {
      title: 'Catgeory',
      dataIndex: 'category_display',
      width: 250,
      sorter: (a, b) => {
        if (a.category_display && b.category_display) {
          return a.category_display.localeCompare(b.category_display);
        }
        return 0;
      }
    },
    {
      title: 'Unit of Measurement',
      dataIndex: 'unit_of_measurement_display',
      width: 200,
      sorter: (a, b) => {
        if (a.unit_of_measurement_display && b.unit_of_measurement_display) {
          return a.unit_of_measurement_display.localeCompare(b.unit_of_measurement_display);
        }
        return 0;
      }
    },        
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (_, item) => {
        if (item.rate_only_item) {
          return 'Rate Only';
        }
        return numeral(item.quantity).format('0,000');
      },
      width: 120,
      sorter: (a, b) => {
        if (!a.rate_only_item && !b.rate_only_item) {
          return Number(a.quantity) - Number(b.quantity);
        } else if (a.rate_only_item) {
          return -1;
        }
        return 1;
      }
    },
    {
      title: 'In Stock',
      dataIndex: 'in_stock_qty',
      render: (text) => (numeral(text).format('0,000')),
      width: 120,
      sorter: (a, b) => Number(a.in_stock_qty) - Number(b.in_stock_qty),
    }, 
    {
      title: 'Amount per item',
      dataIndex: 'amount_per_item',
      render: (text) => (numeral(text).format('$0,000')),
      width: 150,
      sorter: (a, b) => Number(a.amount_per_item) - Number(b.amount_per_item),
    }, 
    {
      title: 'Lead Time (days)',
      dataIndex: 'lead_time_days',
      render: (text) => (numeral(text).format('0,000')),
      width: 150,
      sorter: (a, b) => {
        return Number(a.lead_time_days) - Number(b.lead_time_days);
      },
    }, 
    {
      title: 'Delivery Date',
      dataIndex: 'estimated_delivery_date',
      render: (text) => (moment(text, 'YYYY-MM-DD').format('MM/DD/YYYY')),
      width: 120,
      sorter: (a, b) => {
        return Number(moment(a.estimated_delivery_date, 'YYYY-MM-DD').format('X')) - Number(moment(b.estimated_delivery_date, 'YYYY-MM-DD').format('X'));
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      width: 350,
      sorter: (a, b) => {
        if (a.notes && b.notes) {
          return a.notes.localeCompare(b.notes);
        }
        return 0;
      }
    }, 
    {
      title: 'Gross',
      dataIndex: 'gross_amount',
      render: (_, item) => {
        if (item.rate_only_item) return 'Rate Only';
        return numeral(item.gross_amount).format('$0,000');
      },
      width: 120,
      fixed: 'right',
      sorter: (a, b) => {
        if (!a.rate_only_item && !b.rate_only_item) {
          return Number(a.gross_amount) - Number(b.gross_amount);
        } else if (a.rate_only_item) {
          return -1;
        }
        return 1;
      }
    }
  ];

  const QuotationQuestionReplyColumns: ColumnsType<Partial<QuotationQuestionReply>> = [
    {
      title: 'Question',
      dataIndex: 'material_request_extra_question_display',
      fixed: 'left',
      width: 500,
      render: (text, record) => (<EscapeText text={text}/>),
    },
    {
      title: 'Response',
      dataIndex: 'reply',
      fixed: 'left',
    }
  ]

  if (allowSubmit) {
    QuotationItemColumns.push(
      {
        title: 'Operations',
        width: 90,
        fixed: 'right',
        render: (_, item) => (<>
          {currentVersion.status_display === 'In Progress' && (<>
            <EditQuotationItemModal
              items={items}
              quoteItem={item}
              setItems={setItems}
              updateStatus={updateStatus}/>
            <Popconfirm
              title="Are you sure you wish to deleted this quotation item?"
              onConfirm={() => removeQI(item.id)}
              okText="Yes">
              <Tooltip placement="bottom" title="Delete">
                <Button size="small" style={{marginLeft: '8px'}}><DeleteOutlined/></Button>
              </Tooltip>
            </Popconfirm>
          </>)}
          {currentVersion.status_display !== 'In Progress' && ('--')}
        </>)
      }  
    );

    QuotationQuestionReplyColumns.push(
      {
        title: 'Operations',
        width: 90,
        fixed: 'right',
        render: (_, targetQuestionReply) => (<>
          {currentVersion.status_display === 'In Progress' && (<>
            <EditQuotationQuestionReplyModal
              questionReply={questionReply}
              targetQuestionReply={targetQuestionReply}
              setQuestionReply={setQuestionReply}/>
          </>)}
          {currentVersion.status_display !== 'In Progress' && ('--')}
        </>)
      }
    );
  }

  const removeQI = (id: number|undefined): void => {
    axios.delete(`/base/quotation_item/${id}/`)
    .then(function (response) {
      const newItems = items.filter((item: Partial<QuotationItem>) => item.id !== id);
      setItems([...newItems]);
      updateStatus();
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  const newVersion = (): void => {
    axios({method: 'POST', url: `/base/quotation/new_version/`, data: {id: currentVersion.id}})
    .then(function (response) {
      navigate(`/quotation/${response.data.quotation_header}/?version=${response.data.version}`);
    })
    .catch(function (error) {
      console.log(error)
    });
  };

  const getFile = (id: number|undefined, name: string|undefined) => {
    const url = `${env.BASE_URL}base/quotation_file/${id}/download/`;
    axios.get(url, { responseType: "blob", timeout: 1000000})
    .then(function (response) {
      saveAs(response.data, name);
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  const getQuotationGrossAmountAndRevisedTenderSum = (record: Partial<Quotation>) => {
    return (
      <>
        {!record.gross_amount ? (
          "--"
         ) : 
          <>
            {!record.revised_tender_sum ? (
              numeral(record.gross_amount).format('$0,000.00')
            ) : (
              <s>{numeral(record.gross_amount).format('$0,000.00')}</s>
            )}
            <br />
            {record.revised_tender_sum ? numeral(record.revised_tender_sum).format('$0,000.00') : ''}
          </>
        }
      </>
    )
  }

  const SubmitRevisedLumpSumDiscount = () => {
    if (inputLumpSumDiscountRef.current) {
      const newLumpSumDiscount = {
        lump_sum_discount: inputLumpSumDiscountRef.current.value,
      }
      axios({
        method: 'PATCH',
        url: `/base/quotation/${currentVersion.id}/`,
        data: newLumpSumDiscount
      })
      .then(function (response) {
        setIsRevisingLumpSumDiscount(false);
        updateStatus();
      })
      .catch(function (error) {
        console.log(error)
      });
    }
  }

  const getLumpSumDiscount = (record: Partial<Quotation>) => {
    return (
      <>
        { isRevisingLumpSumDiscount ? (
          <Space>
            <InputNumber
              ref={inputLumpSumDiscountRef}
              size="small"
              style={{ width: 150 }}
              autoFocus
              defaultValue={record.lump_sum_discount ? record.lump_sum_discount : 0}
              placeholder="Enter amount"/>
            <Button
              onClick={SubmitRevisedLumpSumDiscount}
              size="small"
              type="primary">
                <CheckOutlined style={{margin: '0 4px 0 0'}}/>Save
            </Button>
            <Button
              onClick={() => {setIsRevisingLumpSumDiscount(false)}}
              danger
              size="small"
              type="primary">
                <CloseOutlined style={{margin: '0 4px 0 0'}}/>Cancel
            </Button>
          </Space>
        ) : (
          <Space>
            {record.lump_sum_discount ? numeral(record.lump_sum_discount).format('$0,000.00') : '--'}
            { allowSubmit && (
              <Button
                onClick={() => {setIsRevisingLumpSumDiscount(true)}}
                size="small"
                type="primary">
                  <EditOutlined style={{margin: '0 4px 0 0'}}/>Update
              </Button>
            )}
          </Space>
        )}
      </>
    )
  }

  return (<>
  <div className="bread-crumbs">
    <NavLink to="/quotations">Quotations</NavLink>
    <i className="material-icons">chevron_right</i>
    <span>Quotation Details</span>
  </div>
  <div className="object-header">
  {initialLoad ? (<div className="loading padded"><Spin></Spin><span className="msg">loading...</span></div>) :(<>
    <h3><span className="request-title">Quote ID: {currentVersion.display_doc_number}</span></h3>
    <div className="object-meta">
      <Dropdown
        className="header-actions"
        overlay={
          actionsMenu({
            id: currentVersion.id,
            changeStatus,
            confirmCloneQuotation,
            confirmCancelQuotation,
            qItems: items,
            newVersion,
            status: currentVersion.status_display,
            allowSubmit: allowSubmit,
          })
        }
        placement="bottomRight"
        trigger={['click']}>
        <Button>Actions <DownOutlined /></Button>
      </Dropdown>
      <Dropdown overlay={versionMenu({allVersions})} trigger={['click']} placement="bottomRight" className="header-versions">
        <Button>Version: {currentVersion.version} <DownOutlined /></Button>
      </Dropdown>      
      <span className={`request-status ${timelineClass(currentVersion.status_display)}`}>{currentVersion.status_display}</span>   
    </div> 
  </>)}
  </div>   
  {!initialLoad && (<>
    <div className="details-general">
      <div className="details-left">
        <div className="card general">
          <h3>General Information</h3>
          <table className="details-table new">
            <tbody>
              <tr>
                <td><label>Point of Contact:</label>{currentVersion.contact_display}</td>
                <td><label>Supplier:</label>{currentVersion.supplier_display}</td>
                <td><label>Payment Term:</label>{currentVersion.payment_term}</td>
              </tr>
              <tr>
                <td><label>Publish Date:</label><QuotationPublishDate  quotation={currentVersion} alignment="right"/></td>
                <td><label>Expiration Date:</label>{moment(currentVersion.expiration_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
                <td><label>Estimated Delivery Date:</label>{moment(currentVersion.estimated_delivery_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
              </tr>
              <tr>
                <td><label>Gross Amount:</label>{getQuotationGrossAmountAndRevisedTenderSum(currentVersion)}</td>
                <td><label>Lump Sum Discount:</label>{getLumpSumDiscount(currentVersion)}</td>
                <td><label>Net Amount:</label>{currentVersion.net_amount ? numeral(currentVersion.net_amount).format('$0,000.00') : '--'}</td>
              </tr>
            </tbody>
          </table>
          <table className="details-table new">
            <tbody>
              <tr>
                <td >
                  <label style={{marginBottom: '5px'}}>Files:</label>
                  {files.length === 0 && ('--')}
                  {files.map((file: Partial<QuotationFile>) => (
                    <Button 
                      onClick={() => getFile(file.id, file.file_name)}
                      key={file.id?.toString()}
                      style={{margin: '0 8px 8px 0' }}>
                      <DownloadOutlined style={{marginRight: '4px' }}/>
                      {file.file_type_display}
                    </Button> 
                  ))}
                </td>
                <td style={{ width: 'calc((100% / 3) * 2)', maxWidth: 'calc((100% / 3) * 2)'}} dangerouslySetInnerHTML={{__html: `<label>Notes:</label>${currentVersion.notes ? currentVersion.notes : '--'}`}}></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="details-right card">
        <h3>Timeline</h3>
        <ul className="timeline">
        { timeline.map((point: Partial<XrefQuotationStatus>) => (
            <li className="timeline-item" key={String(point.id)}>
              <div className="timeline-item-tail"></div>
              <div className={`timeline-item-dot ${timelineClass(point.status_display)}`}></div>
              <div className="timeline-item-content">
                <span className={`request-status ${timelineClass(point.status_display)}`}>{point.status_display}</span>
                <span className="timeline-item-date">{moment(point.created_at).format('MM/DD/YYYY h:mm a')}</span>
                <p>{point.created_by_display}</p>
              </div>
            </li>         
          ))}        
          <li className="timeline-item">
            <div className="timeline-item-tail"></div>
            <div className="timeline-item-dot white"></div>
            <div className="timeline-item-content">
              <span className="request-status white">created</span><span className="timeline-item-date">{moment(currentVersion.created_at).format('MM/DD/YYYY h:mm a')}</span>
              <p>{currentVersion.created_by_display}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    { tenderQueries.length > 0 && (
      <div className="card general">
        <h5>Tender Query</h5>
        <table className="details-table new">
          <tbody>
            <tr>
              <td><label>Point of Contact:</label>{tenderQueries[0].contact_display}</td>
              <td><label>Request Date:</label>{moment(tenderQueries[0].request_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
              <td><label>Response Deadline:</label>{moment(tenderQueries[0].response_deadline, 'YYYY-MM-DD').format('MM/DD/YYYY')}</td>
            </tr>
            <tr>
              <td colSpan={3}><label>Notes:</label>{tenderQueries[0].notes}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )}
    { items.length === 0 ? (
      <div className="card" style={{margin: '8px 16px'}}>
        <h5>
        {currentVersion.status_display === 'In Progress' && (<AddQuotationItemModal
            activeStatus={activeStatus}
            cssClass="no-quote-items"
            items={items}
            quotation={currentVersion}
            rfqItems={rfqItems}
            setItems={setItems}
            updateStatus={updateStatus}
          />)}
            No Quotation Items
        </h5>
      </div>) :
      (<div className="card" style={{paddingBottom: '16px', margin: '8px 16px'}}>
        <h5>
          {currentVersion.status_display === 'In Progress' && (<AddQuotationItemModal
            activeStatus={activeStatus}
            items={items}
            quotation={currentVersion}
            rfqItems={rfqItems}
            setItems={setItems}
            updateStatus={updateStatus}
          />)}
          Quotation Items<span className="count">{items.length}</span>
        </h5>
        <Table
          columns={QuotationItemColumns}
          dataSource={items}
          expandable={{
            expandedRowRender: record => (<>
              <h5 className="qi-expand-header">Specs:</h5>
              <div className="qi-specs">
                {record.specs?.map((spec: Partial<QuotationItemSpec>) => (
                  <div key={spec.id?.toString()}>
                    <label>{spec.material_request_item_spec_spec}:</label>
                    {spec.spec_response}
                  </div>
                ))}
              </div>
            </>),
            rowExpandable: record => {
              if (record.specs && record.files) {
                return record.specs.length > 0 || record.files.length > 0;
              }
              return false;
            },
          }}
          size="small"
          scroll={{x:1300}}/>
      </div>
    )}
    { questionReply.length === 0 ? (
        <div className="card" style={{margin: '8px 16px'}}>
          <h5>No Extra Questions</h5>
        </div>
      ) : (
        <div className="card" style={{paddingBottom: '16px', margin: '8px 16px'}}>
          <h5>
            Extra Questions<span className="count">{questionReply.length}</span>
          </h5>
          <Table
            columns={QuotationQuestionReplyColumns}
            dataSource={questionReply}
            size="small"/>
        </div>
      )
    }
    <div className="card" style={{borderTop: '3px solid #003673', paddingBottom: '16px', margin: '8px 16px 16px'}}>
      <h5 style={{borderBottom: '1px solid #ddd', margin: '0 -16px 8px', padding: '0 16px 16px'}}>
        RFQ: <EscapeText text={RFQ.rfq_title}/>
        <br/>
        <NavLink className="rfq-id" to={`/rfq/${RFQ.rfq_header}/?version=${RFQ.version}`}>{RFQ.display_doc_number}</NavLink>
      </h5>
      <RFQDetailsTable
        description={RFQ.rfq_desc}
        budget={RFQ.target_budget}
        category={RFQ.rfq_category_display}
        contact={RFQ.contact_display}
        notes={RFQ.notes}
        mrq_header={RFQ.mrq_header_id}
        mrq_version={RFQ.mrq_version}
        mrq_doc={RFQ.mrq_display_doc_number}
        publications={publications}/>
      { rfqItems.length === 0 ? (
        <><hr/><h5>No RFQ Items</h5></>) :
        (<>
          <hr/>
          <h5>RFQ Items<span className="count">{rfqItems.length}</span></h5>
          <Table columns={RFQItemColumns} dataSource={rfqItems} size="small"/>
        </>)
      }
    </div>
  </>)}
</>
);
};

export default QuoteDetails;
